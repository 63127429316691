import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _75a6bbf0 = () => interopDefault(import('../pages/insights.vue' /* webpackChunkName: "pages/insights" */))
const _7a5f4e1f = () => interopDefault(import('../pages/insights/my-articles.vue' /* webpackChunkName: "pages/insights/my-articles" */))
const _30df332c = () => interopDefault(import('../pages/insights/topic/_tag.vue' /* webpackChunkName: "pages/insights/topic/_tag" */))
const _82075c40 = () => interopDefault(import('../pages/insights/author/_id/_slug.vue' /* webpackChunkName: "pages/insights/author/_id/_slug" */))
const _3ca60bfe = () => interopDefault(import('../pages/insights/_category/index.vue' /* webpackChunkName: "pages/insights/_category/index" */))
const _63adb2b2 = () => interopDefault(import('../pages/insights/_category/_id/_slug/index.vue' /* webpackChunkName: "pages/insights/_category/_id/_slug/index" */))
const _6a92330a = () => interopDefault(import('../pages/investments.vue' /* webpackChunkName: "pages/investments" */))
const _7c2e246a = () => interopDefault(import('../pages/investments/bluechip.vue' /* webpackChunkName: "pages/investments/bluechip" */))
const _d3381b5e = () => interopDefault(import('../pages/investments/compare.vue' /* webpackChunkName: "pages/investments/compare" */))
const _45174180 = () => interopDefault(import('../pages/investments/etfs.vue' /* webpackChunkName: "pages/investments/etfs" */))
const _2989578c = () => interopDefault(import('../pages/investments/funds.vue' /* webpackChunkName: "pages/investments/funds" */))
const _1cb734e7 = () => interopDefault(import('../pages/investments/global.vue' /* webpackChunkName: "pages/investments/global" */))
const _0a9eba63 = () => interopDefault(import('../pages/investments/hybrids.vue' /* webpackChunkName: "pages/investments/hybrids" */))
const _faec106a = () => interopDefault(import('../pages/investments/ideas/index.vue' /* webpackChunkName: "pages/investments/ideas/index" */))
const _130d5fed = () => interopDefault(import('../pages/investments/income.vue' /* webpackChunkName: "pages/investments/income" */))
const _5d87341e = () => interopDefault(import('../pages/investments/lics.vue' /* webpackChunkName: "pages/investments/lics" */))
const _fdd09f7a = () => interopDefault(import('../pages/investments/long-term-gainers.vue' /* webpackChunkName: "pages/investments/long-term-gainers" */))
const _b0ad8134 = () => interopDefault(import('../pages/investments/screeners.vue' /* webpackChunkName: "pages/investments/screeners" */))
const _1cc5abaf = () => interopDefault(import('../pages/investments/smallcap.vue' /* webpackChunkName: "pages/investments/smallcap" */))
const _78a55061 = () => interopDefault(import('../pages/investments/stocks.vue' /* webpackChunkName: "pages/investments/stocks" */))
const _7a80652e = () => interopDefault(import('../pages/investments/sustainable.vue' /* webpackChunkName: "pages/investments/sustainable" */))
const _38474076 = () => interopDefault(import('../pages/investments/value-core-growth.vue' /* webpackChunkName: "pages/investments/value-core-growth" */))
const _6ba889ea = () => interopDefault(import('../pages/investments/watchlists.vue' /* webpackChunkName: "pages/investments/watchlists" */))
const _fe5952fa = () => interopDefault(import('../pages/investments/ideas/_slug.vue' /* webpackChunkName: "pages/investments/ideas/_slug" */))
const _b0133b72 = () => interopDefault(import('../pages/investments/security/_exchangeCode/index.vue' /* webpackChunkName: "pages/investments/security/_exchangeCode/index" */))
const _0ff854be = () => interopDefault(import('../pages/investments/security/_exchangeCode/_symbol/_view.vue' /* webpackChunkName: "pages/investments/security/_exchangeCode/_symbol/_view" */))
const _00aa9e02 = () => interopDefault(import('../pages/learn.vue' /* webpackChunkName: "pages/learn" */))
const _24519afa = () => interopDefault(import('../pages/learn/_slug.vue' /* webpackChunkName: "pages/learn/_slug" */))
const _1ff56832 = () => interopDefault(import('../pages/markets.vue' /* webpackChunkName: "pages/markets" */))
const _afc2f6f8 = () => interopDefault(import('../pages/markets/asx-market-report/index.vue' /* webpackChunkName: "pages/markets/asx-market-report/index" */))
const _d41f4cba = () => interopDefault(import('../pages/markets/upcoming-dividends/index.vue' /* webpackChunkName: "pages/markets/upcoming-dividends/index" */))
const _498c9b18 = () => interopDefault(import('../pages/markets/global-market-report/_market.vue' /* webpackChunkName: "pages/markets/global-market-report/_market" */))
const _294a6d36 = () => interopDefault(import('../pages/markets/_region.vue' /* webpackChunkName: "pages/markets/_region" */))
const _44cf7c83 = () => interopDefault(import('../pages/portfolio.vue' /* webpackChunkName: "pages/portfolio" */))
const _232b2e0e = () => interopDefault(import('../pages/products.vue' /* webpackChunkName: "pages/products" */))
const _dd643294 = () => interopDefault(import('../pages/products/_product.vue' /* webpackChunkName: "pages/products/_product" */))
const _3dda7e56 = () => interopDefault(import('../pages/security.vue' /* webpackChunkName: "pages/security" */))
const _015494b3 = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _499fbf56 = () => interopDefault(import('../pages/solutions.vue' /* webpackChunkName: "pages/solutions" */))
const _35632f1e = () => interopDefault(import('../pages/solutions/_solution.vue' /* webpackChunkName: "pages/solutions/_solution" */))
const _66114347 = () => interopDefault(import('../pages/x-features.vue' /* webpackChunkName: "pages/x-features" */))
const _2a483ea0 = () => interopDefault(import('../pages/about/advertise.vue' /* webpackChunkName: "pages/about/advertise" */))
const _e6392724 = () => interopDefault(import('../pages/about/careers.vue' /* webpackChunkName: "pages/about/careers" */))
const _7e380b4e = () => interopDefault(import('../pages/about/contact.vue' /* webpackChunkName: "pages/about/contact" */))
const _1ce2ce9c = () => interopDefault(import('../pages/about/methodology.vue' /* webpackChunkName: "pages/about/methodology" */))
const _4c9c5e24 = () => interopDefault(import('../pages/about/story.vue' /* webpackChunkName: "pages/about/story" */))
const _24caef00 = () => interopDefault(import('../pages/about/terms.vue' /* webpackChunkName: "pages/about/terms" */))
const _5348e197 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _62e459cc = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _a6d58836 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _0d48fe68 = () => interopDefault(import('../pages/auth/set-password.vue' /* webpackChunkName: "pages/auth/set-password" */))
const _0e4c58f1 = () => interopDefault(import('../pages/auth/set-password-trial.vue' /* webpackChunkName: "pages/auth/set-password-trial" */))
const _5d617f2c = () => interopDefault(import('../pages/auth/thank-you.vue' /* webpackChunkName: "pages/auth/thank-you" */))
const _1444b838 = () => interopDefault(import('../pages/auth/trial-ended-notice.vue' /* webpackChunkName: "pages/auth/trial-ended-notice" */))
const _77416ca4 = () => interopDefault(import('../pages/auth/trial-signup-investor.vue' /* webpackChunkName: "pages/auth/trial-signup-investor" */))
const _5add4f6c = () => interopDefault(import('../pages/error/404.vue' /* webpackChunkName: "pages/error/404" */))
const _e0e9a6ae = () => interopDefault(import('../pages/error/500.vue' /* webpackChunkName: "pages/error/500" */))
const _6d353890 = () => interopDefault(import('../pages/newsletters/subscribe.vue' /* webpackChunkName: "pages/newsletters/subscribe" */))
const _bedcc00c = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _2a40a956 = () => interopDefault(import('../pages/auth/thank-you-trial-signup-investor/_status.vue' /* webpackChunkName: "pages/auth/thank-you-trial-signup-investor/_status" */))
const _26015b38 = () => interopDefault(import('../pages/lp/_page.vue' /* webpackChunkName: "pages/lp/_page" */))
const _9c50576c = () => interopDefault(import('../pages/search/_type/index.vue' /* webpackChunkName: "pages/search/_type/index" */))
const _61b34cc0 = () => interopDefault(import('../pages/sitemap/_year/index.vue' /* webpackChunkName: "pages/sitemap/_year/index" */))
const _566a97f2 = () => interopDefault(import('../pages/thank-you/_slug.vue' /* webpackChunkName: "pages/thank-you/_slug" */))
const _7c10e846 = () => interopDefault(import('../pages/sitemap/_year/_month/index.vue' /* webpackChunkName: "pages/sitemap/_year/_month/index" */))
const _72d8948d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/insights",
    component: _75a6bbf0,
    name: "insights",
    children: [{
      path: "my-articles",
      component: _7a5f4e1f,
      name: "insights-my-articles"
    }, {
      path: "topic/:tag?",
      component: _30df332c,
      name: "insights-topic-tag"
    }, {
      path: "author/:id?/:slug?",
      component: _82075c40,
      name: "insights-author-id-slug"
    }, {
      path: ":category",
      component: _3ca60bfe,
      name: "insights-category"
    }, {
      path: ":category?/:id/:slug",
      component: _63adb2b2,
      name: "insights-category-id-slug"
    }]
  }, {
    path: "/investments",
    component: _6a92330a,
    name: "investments",
    children: [{
      path: "bluechip",
      component: _7c2e246a,
      name: "investments-bluechip"
    }, {
      path: "compare",
      component: _d3381b5e,
      name: "investments-compare"
    }, {
      path: "etfs",
      component: _45174180,
      name: "investments-etfs"
    }, {
      path: "funds",
      component: _2989578c,
      name: "investments-funds"
    }, {
      path: "global",
      component: _1cb734e7,
      name: "investments-global"
    }, {
      path: "hybrids",
      component: _0a9eba63,
      name: "investments-hybrids"
    }, {
      path: "ideas",
      component: _faec106a,
      name: "investments-ideas"
    }, {
      path: "income",
      component: _130d5fed,
      name: "investments-income"
    }, {
      path: "lics",
      component: _5d87341e,
      name: "investments-lics"
    }, {
      path: "long-term-gainers",
      component: _fdd09f7a,
      name: "investments-long-term-gainers"
    }, {
      path: "screeners",
      component: _b0ad8134,
      name: "investments-screeners"
    }, {
      path: "smallcap",
      component: _1cc5abaf,
      name: "investments-smallcap"
    }, {
      path: "stocks",
      component: _78a55061,
      name: "investments-stocks"
    }, {
      path: "sustainable",
      component: _7a80652e,
      name: "investments-sustainable"
    }, {
      path: "value-core-growth",
      component: _38474076,
      name: "investments-value-core-growth"
    }, {
      path: "watchlists",
      component: _6ba889ea,
      name: "investments-watchlists"
    }, {
      path: "ideas/:slug",
      component: _fe5952fa,
      name: "investments-ideas-slug"
    }, {
      path: "security/:exchangeCode",
      component: _b0133b72,
      name: "investments-security-exchangeCode"
    }, {
      path: "security/:exchangeCode?/:symbol/:view?",
      component: _0ff854be,
      name: "investments-security-exchangeCode-symbol-view"
    }]
  }, {
    path: "/learn",
    component: _00aa9e02,
    name: "learn",
    children: [{
      path: ":slug?",
      component: _24519afa,
      name: "learn-slug"
    }]
  }, {
    path: "/markets",
    component: _1ff56832,
    name: "markets",
    children: [{
      path: "asx-market-report",
      component: _afc2f6f8,
      name: "markets-asx-market-report"
    }, {
      path: "upcoming-dividends",
      component: _d41f4cba,
      name: "markets-upcoming-dividends"
    }, {
      path: "global-market-report/:market?",
      component: _498c9b18,
      name: "markets-global-market-report-market"
    }, {
      path: ":region?",
      component: _294a6d36,
      name: "markets-region"
    }]
  }, {
    path: "/portfolio",
    component: _44cf7c83,
    name: "portfolio"
  }, {
    path: "/products",
    component: _232b2e0e,
    name: "products",
    children: [{
      path: ":product?",
      component: _dd643294,
      name: "products-product"
    }]
  }, {
    path: "/security",
    component: _3dda7e56,
    name: "security"
  }, {
    path: "/sitemap",
    component: _015494b3,
    name: "sitemap"
  }, {
    path: "/solutions",
    component: _499fbf56,
    name: "solutions",
    children: [{
      path: ":solution?",
      component: _35632f1e,
      name: "solutions-solution"
    }]
  }, {
    path: "/x-features",
    component: _66114347,
    name: "x-features"
  }, {
    path: "/about/advertise",
    component: _2a483ea0,
    name: "about-advertise"
  }, {
    path: "/about/careers",
    component: _e6392724,
    name: "about-careers"
  }, {
    path: "/about/contact",
    component: _7e380b4e,
    name: "about-contact"
  }, {
    path: "/about/methodology",
    component: _1ce2ce9c,
    name: "about-methodology"
  }, {
    path: "/about/story",
    component: _4c9c5e24,
    name: "about-story"
  }, {
    path: "/about/terms",
    component: _24caef00,
    name: "about-terms"
  }, {
    path: "/auth/login",
    component: _5348e197,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _62e459cc,
    name: "auth-logout"
  }, {
    path: "/auth/register",
    component: _a6d58836,
    name: "auth-register"
  }, {
    path: "/auth/set-password",
    component: _0d48fe68,
    name: "auth-set-password"
  }, {
    path: "/auth/set-password-trial",
    component: _0e4c58f1,
    name: "auth-set-password-trial"
  }, {
    path: "/auth/thank-you",
    component: _5d617f2c,
    name: "auth-thank-you"
  }, {
    path: "/auth/trial-ended-notice",
    component: _1444b838,
    name: "auth-trial-ended-notice"
  }, {
    path: "/auth/trial-signup-investor",
    component: _77416ca4,
    name: "auth-trial-signup-investor"
  }, {
    path: "/error/404",
    component: _5add4f6c,
    name: "error-404"
  }, {
    path: "/error/500",
    component: _e0e9a6ae,
    name: "error-500"
  }, {
    path: "/newsletters/subscribe",
    component: _6d353890,
    name: "newsletters-subscribe"
  }, {
    path: "/user/profile",
    component: _bedcc00c,
    name: "user-profile"
  }, {
    path: "/auth/thank-you-trial-signup-investor/:status?",
    component: _2a40a956,
    name: "auth-thank-you-trial-signup-investor-status"
  }, {
    path: "/lp/:page?",
    component: _26015b38,
    name: "lp-page"
  }, {
    path: "/search/:type",
    component: _9c50576c,
    name: "search-type"
  }, {
    path: "/sitemap/:year",
    component: _61b34cc0,
    name: "sitemap-year"
  }, {
    path: "/thank-you/:slug?",
    component: _566a97f2,
    name: "thank-you-slug"
  }, {
    path: "/sitemap/:year/:month",
    component: _7c10e846,
    name: "sitemap-year-month"
  }, {
    path: "/",
    component: _72d8948d,
    name: "index"
  }, {
    path: "*",
    component: _5add4f6c,
    name: "error-page-not-found"
  }, {
    path: "/investments/security/:exchangeCode/:symbol/:view?",
    component: _0ff854be,
    name: "mca-security"
  }, {
    path: "/mca-subscription/details",
    redirect: {"name":"offers"},
    name: "mca-subscription"
  }, {
    path: "/insights/:category/:id/:slug",
    redirect: (to) => {
    if (to.params.category === 'investing') {
      return {
        name: 'mca-learn-article',
        params: {
          category: to.params.category,
          id: to.params.id,
          slug: to.params.slug
        }
      };
    } else
    {
      return {
        name: 'insights-category-id-slug',
        params: {
          category: to.params.category,
          id: to.params.id,
          slug: to.params.slug
        }
      };
    }
  },
    name: "mca-article"
  }, {
    path: "/learn/:category/:id/:slug",
    component: _63adb2b2,
    name: "mca-learn-article"
  }, {
    path: "/insights/:category",
    component: _3ca60bfe,
    name: "mca-news-category"
  }, {
    path: "/learn/:slug",
    redirect: () => {
    return {
      name: 'learn-slug',
      params: {
        slug: "investing-guides"
      }
    };
  },
    name: "mca-learn-category"
  }, {
    path: "/insights/author/:id/:slug",
    redirect: (to) => {
    return {
      name: 'insights-author-id-slug',
      params: {
        id: to.params.id,
        slug: to.params.slug
      }
    };
  },
    name: "mca-author"
  }, {
    path: "/insights/topic/:tag",
    redirect: (to) => {
    return {
      name: 'insights-topic-tag',
      params: {
        tag: to.params.tag
      }
    };
  },
    name: "mca-tag"
  }, {
    path: "/mca-faq/:category/:slug",
    redirect: (to) => {
    return {
      name: 'faq',
      params: {
        category: to.params.category,
        slug: to.params.slug
      }
    };
  },
    name: "mca-faq"
  }, {
    path: "/search/:type/:securityType?",
    component: _9c50576c,
    name: "mca-search"
  }, {
    path: "/about/contact",
    component: _7e380b4e,
    name: "mca-contact"
  }, {
    path: "/about/terms",
    component: _24caef00,
    name: "mca-terms"
  }, {
    path: "/about/advertise",
    component: _2a483ea0,
    name: "mca-advertising"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
