import { IBestIdeasSection, BestIdeasCard, BestIdeasModal } from '~/types/investment/ideas';

export default class BestIdeasSection implements IBestIdeasSection {

    public title: string;
    public bestIdeaCards: {
        uid: string;
        caption: string;
        buttonLabel: string;
        heading: string;
        backgroundImageUrl: string;
        backgroundImageAltText: string;
    }[];
    public bestIdeaModals: {
        caption: string;
        buttonLabel: string;
        heading: string;
        label: string;
        metaText: string;
        backgroundImageUrl: string;
        backgroundImageAltText: string;
        startNowLink: string;
        buttonIcon: string;
        buttonSize: string;
    }[];

    constructor(title: string, modularBlocks: [{
        best_ideas_card_and_modal: {
            best_ideas_card: BestIdeasCard[],
            best_ideas_modal: BestIdeasModal[]
        }
    }]) {
        this.title = title;
        this.bestIdeaCards = [];
        this.bestIdeaModals = [];

        modularBlocks.forEach((({ best_ideas_card_and_modal: {
            best_ideas_card, best_ideas_modal
        } }) => {

            this.bestIdeaCards.push({
                uid: best_ideas_card[0].uid,
                caption: best_ideas_card[0].caption,
                buttonLabel: best_ideas_card[0].button[0].label,
                backgroundImageUrl: best_ideas_card[0].supplemental_content_image_.url,
                backgroundImageAltText: best_ideas_card[0].button[0].title,
                heading: best_ideas_card[0].heading,
            });
            this.bestIdeaModals.push({
                caption: best_ideas_modal[0].caption,
                heading: best_ideas_modal[0].heading,
                backgroundImageUrl: best_ideas_modal[0].image.url,
                backgroundImageAltText: best_ideas_modal[0].button[0].title,
                buttonLabel: best_ideas_modal[0].button[0].label,
                startNowLink: best_ideas_modal[0].button[0].meta.url.href,
                label: best_ideas_modal[0].label,
                metaText: best_ideas_modal[0].meta_text,
                buttonIcon: best_ideas_modal[0].button[0].icon_name,
                buttonSize: best_ideas_modal[0].button[0].size,
            });
        }));
    }
}
