import { ActionTree, MutationTree, GetterTree } from 'vuex';

export const state = () => ({
    maasToken: '',
    host: ''
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {
    SET_MAAS_TOKEN(state, token: string) {
        state.maasToken = token;
    },
    SET_HOSTNAME(state, host: string) {
        state.host = host;
    }
};

export const actions: ActionTree<State, State> = {
    async nuxtServerInit({ commit, dispatch }, { req, route }) {
        commit('SET_HOSTNAME', process.env.BASE_URL || req.headers.host);

        if(route.query.user_segment) {
            try {
                await dispatch('user/saveUserSegment', route.query.user_segment);
            }
            catch(e) {
                console.error('Error setting user segment:', e);
            }
        }
    }
};

export const getters: GetterTree<State, State> = {

};