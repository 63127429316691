
import Vue from 'vue';
import {MdsButton, MdsButtonContainer} from '@mds/button';
import MdsModal from '@mds/modal';
import MdsIcon from '@mds/icon';
import { PROFESSIONAL_SEGMENTS } from '@/configs/user';
import type {UserSegment} from '@/configs/user';
import { Gtm } from "~/configs/common";

type PrimaryUserSegment = 'indinv' | 'fp';

export default Vue.extend({
    data() {
        return {
            primarySegment: undefined as PrimaryUserSegment | undefined,
            segment: undefined as UserSegment | undefined,
            modal :{
                delay: 3000,
                show: false
            },
            PROFESSIONAL_SEGMENTS
        };
    },
    components: {
        MdsButton,
        MdsButtonContainer,
        MdsModal,
        MdsIcon
    },
    created() {
        if(!this.isSegmentSaved) {
            setTimeout(() => {
                this.modal.show = true;

                this.$gtm.push({
                    event: Gtm.eventName,
                    category: 'segmentation',
                    action: 'open-segmentation-modal',
                    label: 'Open Segmentation Modal'
                });

                Vue.nextTick(() => {
                    (this.$refs.heading1 as HTMLHeadingElement).focus();
                });

            }, this.modal.delay);
        }
    },
    computed: {
        isFinancialProfessional(): boolean {
            return this.primarySegment === 'fp';
        },
        getUserSegment(): UserSegment | undefined {
            return this.$store.state.user.segment;
        },
        isSegmentSaved(): boolean {
            return this.$store.state.user.isSaved;
        }
    },
    methods: {
        onFormSubmit() {

            try {
                if(!this.primarySegment) {
                    throw new Error('Primary segment not selected');
                }

                if(this.primarySegment === 'indinv') {
                    this.$store.dispatch('user/saveUserSegment', this.primarySegment);
                }
                else {
                    if(!this.segment) {
                        throw new Error('Professional segment not selected');
                    }

                    this.$store.dispatch('user/saveUserSegment', this.segment);
                }

                this.$gtm.push({
                    event: Gtm.eventName,
                    category: 'segmentation',
                    action: 'segment-confirmed',
                    label: 'Confirmed segmentation selection'
                });
            }
            catch(e) {
                this.$gtm.push({
                    event: Gtm.eventName,
                    category: 'segmentation',
                    action: 'segment-failed',
                    label: `Failed segmentation selection: ${e}`
                });
            }

            this.modal.show = false;
        },
        reset() {
            this.primarySegment = undefined;
            this.segment = undefined;
        }
    }
});
