import {ActionTree, MutationTree} from "vuex";
import { State as RootState } from "~/store";
import {IPagedResponse, IArticlePayload} from "~/types/article/article";
import ArticleService from "~/services/article/articleService";

export const state = ()=>({
    news: {
        documents: [],
        total: 0,
        page: 1,
        pageSize: 25
    } as IPagedResponse,
    docPage: 1
});

export type State= ReturnType<typeof state>;

export const mutations: MutationTree<State>={
    SET_ARTICLE_DATA:(state: State, news: IPagedResponse)=>{
        state.news=news;
    },
    SET_DOC_PAGE:(state: State, docPage: number) => {
        state.docPage = docPage;
    },
};

export const actions: ActionTree<RootState,State>= {
    async fetchArticles({ commit }, payload:IArticlePayload) {
        const service= new ArticleService(this.app.apolloProvider!.defaultClient);
        const articleResponse= await service.getArticle(payload);
        const news = articleResponse.data.getNews;
        commit ('SET_ARTICLE_DATA',news);
    },
    async currentPage({ commit }, payload:number) {
        commit ('SET_DOC_PAGE',payload);
    }
};