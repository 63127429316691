// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".segmentation-modal .mds-modal__wrapper__mca-dfd{max-width:750px!important}.segmentation-modal .mds-modal__content__mca-dfd{overflow-y:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mds-modal__wrapper": "mds-modal__wrapper__mca-dfd",
	"mds-modal__content": "mds-modal__content__mca-dfd"
};
module.exports = ___CSS_LOADER_EXPORT___;
