export type UserSegment     = 'unknown' | 'indinv' | 'fp-financial-adviser' | 'fp-dealer-group' | 'fp-wealth-management' | 'fp-consultant-investment-adviser' | 'fp-asset-management' | 'fp-private-bank' | 'fp-accountant' | 'fp-other';
export type UserVariation   = 'a' | 'b';

export const DEFAULT_USER_SEGMENT: UserSegment = 'unknown';

export const INDIVIDUAL_SEGMENTS = {
    'indinv': 'Individual'
} as Record<UserSegment, string>;

export const PROFESSIONAL_SEGMENTS = {
    'fp-financial-adviser': 'Financial Advice Practice',
    'fp-dealer-group': 'Dealer Group',
    'fp-wealth-management': 'Wealth Management',
    'fp-consultant-investment-adviser': 'Consultant/Investment Adviser',
    'fp-asset-management': 'Asset Management',
    'fp-private-bank': 'Private Bank/Trust Company',
    'fp-accountant': 'Accountant',
    'fp-other': 'Other',
} as Record<UserSegment, string>;

export const SEGMENT_COOKIE_NAME = 'mca.user.segment';