import { ActionTree, GetterTree, MutationTree } from "vuex";

import { AdvertPlacement, PUSHDOWN_SESSION_TIME } from "~/configs/adverts";
import { State as RootState } from "~/store";
import advertsService from "~/services/adverts";

export type State = ReturnType<typeof state>
export interface IPayload {
    routeName: string,
    isDesktop: boolean;
    customTags: Record<string,string>
}

export const state = () => ({
    placements: new Map<AdvertPlacement, string>(),

    /**
     * Date and time the pushdown splash was last viewable by the user
     */
    lastViewedAt: new Date(0)
});

export const mutations: MutationTree<State> = {
    SET_ADVERT_PLACEMENTS: (state, advertTemplate: Map<AdvertPlacement, string>) => {
        state.placements = advertTemplate;
    },
    LAST_SAVED_VIEW: (state, lastViewedAt: Date) => {
        state.lastViewedAt = lastViewedAt;
    },
    RESET(state) {
        state.placements = new Map();
    }
};

export const actions: ActionTree<RootState, State> = {

    async load({ commit, getters, rootState }, payload: IPayload) {
        const { routeName, customTags } = payload;
        const { auth } = rootState as unknown as { auth: any };
        const isBot = await this.dispatch('adverts/checkBot');
        const isIndividualUserType = customTags.firmType === 'indinv';
        const userType = isBot ? 'b' : (auth.loggedIn ? (isIndividualUserType ? 'f' : 'fa') : 'v');
        const tags = { ...customTags, usrt: userType };
        const service       = new advertsService(routeName, tags, payload.isDesktop, getters.isPulldownSplashViewable);
        const placements    = await service.getAdverts();

        commit('SET_ADVERT_PLACEMENTS', placements);
    },

    async checkBot(): Promise<boolean> {

        return new Promise((resolve) => {

            if(typeof window.isMoatBot === "boolean") {
                resolve(window.isMoatBot);
            }
            else {
                document.addEventListener('isBot', (event: Event) => {
                    resolve((event as CustomEvent).detail);
                });
            }

            setTimeout(() => {
                resolve(false);
            }, 3000);
        });
    }
};

export const getters: GetterTree<State, RootState> = {
    /**
     * Checks if pushdown splash can be viewed based on last session time
     * @returns true if more than 2 hours since last view
     */
    isPulldownSplashViewable: (state) => {

        const timeSinceLastView     = (new Date()).getTime() - (state.lastViewedAt).getTime();
        const hoursSinceLastViewed  = (timeSinceLastView / 1000 / 60 / 60);

        return hoursSinceLastViewed > PUSHDOWN_SESSION_TIME;
    },
    placements: (state) => {
        return state.placements;
    }
};