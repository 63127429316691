
import Vue from 'vue';
import type{ MetaInfo } from "vue-meta";

export default Vue.extend({
    props: ['error'],
    layout: 'error',
    head(): MetaInfo {
        return {
            title: this.error.statusCode === 404 ? 'Error 404 (Not Found)' : 'Error something went wrong'
        };
    },
    data() {
        return {
            
        };
    },
    components: {
        
    }
});
