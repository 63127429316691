import { ApolloClient } from 'apollo-client';
import GetEloquaFormQuery from "~/services/queries/GetEloquaFormQuery.gql";
import SubmitEloquaForm from "~/services/mutations/submitEloquaForm.gql";
import { MCA } from '@mca-components-ui/types';
import type { Types as ApiSchemaType } from '@mca-dfd-api/graphql-schema';

export default class EloquaFormService {
    constructor(public gqlClient: ApolloClient<any>) { }

    async getForm(id: string, guid?: string) {
        const response = await this.gqlClient.query<EloquaFormResponse>({
            query: GetEloquaFormQuery,
            variables: {
                id,
                guid
            }
        });
        return response.data.getEloquaForm;
    }

    async submitForm(id: string, fields: MCA.Forms.IEloquaFormElementValue[], guid?: string) {
        const response = await this.gqlClient.mutate<EloquaFormSubmitResponse>({
            mutation: SubmitEloquaForm,
            variables: {
                input: {
                    id,
                    guid: guid && guid.length > 0 ? guid : undefined,
                    fields
                }
            }
        });

        return response.data?.submitEloquaForm;
    }
}

export interface EloquaFormResponse {
    getEloquaForm: MCA.Forms.IEloquaFormData;
}

export interface EloquaFormSubmitResponse {
    submitEloquaForm: ApiSchemaType.EloquaFormSubmitResponse;
}

export type EloquaFormElementValidationCondition = "IsRequiredCondition";
