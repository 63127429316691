
import Vue from 'vue';
import MdsPageShell from "@mds/page-shell";
import Logo from "~/assets/logo.svg";

export default Vue.extend({
    props: ['error'],
    layout: 'error',
    data() {
        return {
            Logo
        };
    },
    components: {
        MdsPageShell
    }
});
