import { ActionTree, MutationTree } from "vuex";
import { ISearchDocument, ISearchSecurity } from "~/types/document/ISearchResponse";

import ISearchRequest from "~/types/document/ISearchRequest";
import { State as RootState } from "~/store";
import SearchService from "~/services/search/search";

export type State = ReturnType<typeof state>;

export const state = () => ({
    isOpen: false,
    searchSecurity: {} as ISearchSecurity,
    searchDocument: {} as ISearchDocument,
    docPage: 1
});

export const mutations: MutationTree<State> = {
    SET_SEARCH:(state: State, isOpen: boolean) => {
        state.isOpen = isOpen;
    },
    SET_SECURITIES: (state: State, data: ISearchSecurity) => {
        state.searchSecurity = data;
    },
    SET_DOCUMENTS: (state: State, document: ISearchDocument) => {
        state.searchDocument = document;
    },
    SET_DOC_PAGE:(state: State, docPage: number) => {
        state.docPage = docPage;
    }
};

export const actions: ActionTree<RootState, State> = {
    async fetchDocuments({ commit }, searchRequest: ISearchRequest) {
        const service = new SearchService(this.app.apolloProvider!.defaultClient);
        const response = await service.getSearchDocuments(searchRequest);
        commit("SET_DOCUMENTS", response);
    },
    async fetchSecurities({ commit }, searchRequest: ISearchRequest) {
        const service = new SearchService(this.app.apolloProvider!.defaultClient);
        const response = await service.getSecurities(searchRequest);
        commit("SET_SECURITIES", response);
        return response;
    },
    async fetchSecuritiesByType({ commit }, searchRequest: ISearchRequest) {
        const service = new SearchService(this.app.apolloProvider!.defaultClient);
        const response = await service.getSecuritiesByType(searchRequest);
        commit("SET_SECURITIES", response);
        return response;
    },
    async currentPage({ commit }, payload:number) {
        commit ('SET_DOC_PAGE',payload);
    }
};
