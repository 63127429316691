// @ts-nocheck

import { OpenIDConnectScheme } from '~auth/runtime';

export default class UIMScheme extends OpenIDConnectScheme {
    logout() {

        this.$auth.reset();

        const url = `${this.options.endpoints.logout}?client_id=${encodeURIComponent(this.options.clientId || '')}&returnTo=${encodeURIComponent(this.logoutRedirectURI)}`;

        window.location.replace(url);
    }
}