import * as ContentStackCommon from '@mca-components-ui/contentstack-common';
import * as ContentStackProducts from '@mca-components-ui/contentstack-products';
import * as ContentStackSolutions from '@mca-components-ui/contentstack-solutions';

import Vue, { VueConstructor } from 'vue';

export const COMPONENTS = new Map<ContentStackCommon.Types.BlockId | ContentStackProducts.Types.BlockId | ContentStackSolutions.Types.BlockId, VueConstructor<Vue>>([
    ['cta', ContentStackCommon.Cta],
    ['introduction', ContentStackCommon.Intro],
    ['disclaimer', ContentStackCommon.Disclaimer],
    ['value_prop', ContentStackCommon.ValueProp],
    ['awards', ContentStackProducts.Awards],
    ['case_studies', ContentStackCommon.CaseStudies],
    ['capabilities_1', ContentStackProducts.Capabilities1],
    ['capabilities_2', ContentStackProducts.Capabilities2],
    ['features_1', ContentStackProducts.Features1],
    ['features_2', ContentStackProducts.Features2],
    ['process', ContentStackProducts.Process],
    ['capabilities', ContentStackSolutions.Capabilities],
    ['advice_timeline_process', ContentStackSolutions.AdviceTimelineProcess],
    ['whitepaper_single', ContentStackSolutions.WhitepaperSingle],
    ['whitepaper_multiple', ContentStackSolutions.WhitepaperMultiple]]);

export const ABOUT_US = {
    ContentType: 'about',
    Contact_Us_Entry_Id: 'blta46c81f585dc66d4',
    Advertise_With_Us_Entry_Id: 'blt422c5fb51498024f',
    Terms_Condition_Entry_Id: 'blt155994f83e35ab38'
};

export const LEARN_INVEST = {
    ContentType: 'learn_pages',
    Investing_Guides: 'blta9510b62236d1252',
    Webinars_Id: 'blt955006ba440fa14a',
    Podcasts_Id: 'blt8d05dfbe920bffb2',
    Articles_Id: 'bltb48386b47f356959',
    Getting_Started: 'blt0722373d52d0982f',
    Goals_Based_Portfolio_Construction: "blt9de50904be7ea893",
    Portfolio_Maintenance: "bltd187a8cda8abaf2c",
    Retirement_Strategies: "blt799f3d552611ae51",
    Morningstar_Equity_Research_Methodology: "bltd55f6b32d0f7114c",
    Morningstar_Manager_Research_Methodology: "blt3f83e75131ae8060",
    Stocks: "bltef9c1ad90d162a31",
    Bonds: "bltaf6a2e6421fd8e68",
    ETFs_Funds: "bltfb2b55e1aa49d68b",
    LICs: "blt1aa04ffd574ee626"
};

export const WEBINAR_PODCAST = {
    ContentType: 'webinars_and_podcasts',
    Webinar_Podcast_Id: "blt9784fdfddcbb001b"
};

export const THANK_YOU = new Map<string, { contentType: string; entryId: string }>([
    ["newsletter-financial-professional", {
        contentType: 'thankyou_pages_professional',
        entryId: 'blt10c81c57e670037e',
    }],
    ["newsletter-individual-investor", {
        contentType: 'thankyou_pages_individual',
        entryId: 'blt566c05739d3fec3f'
    }]
]);

export const BEST_IDEAS_SECTION = {
    ContentType: 'best_ideas_cards',
    EntryId: "blte375916d0ef2cfc6",
};

export const NEWSLETTER_SIGNUP_PAGE = {
    ContentType: 'newsletter',
    EntryId: "blt338eaffeb5f57318",
};

export const SECURITY_CTA = {
    ContentType: 'security_cta',
    EntryId: "bltad7603b9b5091af7",
};

export const NEWSLETTER_CTA = {
    ContentType: 'newsletter_cta'
};

export const FOOTER_PAGE = {
    ContentType: 'footer',
    EntryId: "blt7ae420b9a8d9e394",
};

export const HEAD_META = {
    ContentType: 'static_pages'
};

export const RECOMMENDED_SECTION_SAL = {
    ContentType: 'recommended_section_default'
};

export const ANALYST_REPORTS = {
    ContentType: 'analyst_report_listings',
    EntryId: "bltc8cc1c2d669772bd",
};

export const ASX_LANDING_PAGE = {
    ContentType : 'asx_landing_page',
    EntryId : 'bltb041aaf35d28f9af'
};

export const ASX_STATIC_PAGE_ENTRIES = {
    ContentType : 'static_pages',
    EntryId : 'blt9042d4645a7f38ac'
}

export const STOCK_EXCHANGE_ENTRIES = new Map<string, { contentType: string; entryId: string }>([
    ["ASX", {
        contentType: 'asx_landing_page',
        entryId: 'bltb041aaf35d28f9af',
    }],
    ["TSX", {
        contentType: 'asx_landing_page',
        entryId: 'blt43e333b1b77ede84'
    }],
    ["AMEX", {
        contentType: 'asx_landing_page',
        entryId: 'bltd7fb5628ff9d2733'
    }],
    ["BER", {
        contentType: 'asx_landing_page',
        entryId: 'blt471ee62f41621bba'
    }],
    ["HAM", {
        contentType: 'asx_landing_page',
        entryId: 'bltd5f7f4c53e60ea86'
    }],
    ["XMAD", {
        contentType: 'asx_landing_page',
        entryId: 'bltfd00a9ef3ecfc576'
    }],
    ["MIL", {
        contentType: 'asx_landing_page',
        entryId: 'bltca3b9f88706b06e2'
    }],
    ["BOM", {
        contentType: 'asx_landing_page',
        entryId: 'blt61e1a486b57280d4'
    }],
    ["DUS", {
        contentType: 'asx_landing_page',
        entryId: 'blt84405abca54573c0'
    }],
    ["BATS", {
        contentType: 'asx_landing_page',
        entryId: 'blt79248df750aa6dbf'
    }],
    ["XAMS", {
        contentType: 'asx_landing_page',
        entryId: 'blt8f7fbe9b2a396693'
    }],
    ["XBRU", {
        contentType: 'asx_landing_page',
        entryId: 'bltb69e55331ff7ec0a'
    }],
    ["XPAR", {
        contentType: 'asx_landing_page',
        entryId: 'bltdbafee832c6599bc'
    }],
    ["HKSE", {
        contentType: 'asx_landing_page',
        entryId: 'blt22b18bf4e06c51e2'
    }],
    ["XKRX", {
        contentType: 'asx_landing_page',
        entryId: 'blt64792aebd2f3523c'
    }],
    ["LSE", {
        contentType: 'asx_landing_page',
        entryId: 'blt48765aa3250e630f'
    }],
    ["OHEL", {
        contentType: 'asx_landing_page',
        entryId: 'blte8a78ddce0193820'
    }],
    ["OSTO", {
        contentType: 'asx_landing_page',
        entryId: 'blta067fafe5a1cfdc6'
    }],
    ["NASDAQ", {
        contentType: 'asx_landing_page',
        entryId: 'blt95ad7068c1106062'
    }],
    ["NSE", {
        contentType: 'asx_landing_page',
        entryId: 'blt6551b4aac5ab3659'
    }],
    ["NYSE", {
        contentType: 'asx_landing_page',
        entryId: 'bltadde1499edafec11'
    }],
    ["NZSE", {
        contentType: 'asx_landing_page',
        entryId: 'blt1d48e54eba1ea5c0'
    }],
    ["HAN", {
        contentType: 'asx_landing_page',
        entryId: 'blt46270115bf67edfe'
    }],
    ["ARCA", {
        contentType: 'asx_landing_page',
        entryId: 'blt375ca127bdfa74f6'
    }],
    ["OSL", {
        contentType: 'asx_landing_page',
        entryId: 'blt5748d76636d7a74b'
    }],
    ["SHSE", {
        contentType: 'asx_landing_page',
        entryId: 'bltb0c64ea181b844ed'
    }],
    ["SZSE", {
        contentType: 'asx_landing_page',
        entryId: 'blt98b56c0f95f1a80a'
    }],
    ["SGX", {
        contentType: 'asx_landing_page',
        entryId: 'blt5d2af502a3d0e97c'
    }],
    ["XSWX", {
        contentType: 'asx_landing_page',
        entryId: 'blt1a78fcc4c2033756'
    }],
    ["TPE", {
        contentType: 'asx_landing_page',
        entryId: 'blt9282b6c86ec67fb7'
    }],
    ["TSE", {
        contentType: 'asx_landing_page',
        entryId: 'blt18fc93ab2f542258'
    }],
    ["TSX", {
        contentType: 'asx_landing_page',
        entryId: 'blt43e333b1b77ede84'
    }],
    ["XETR", {
        contentType: 'asx_landing_page',
        entryId: 'blt6d31a3b515074b62'
    }],
]);

export const STOCK_EXCHANGE_HEADMETA_ENTRIES = new Map<string, { contentType: string; entryId: string }>([
    ["ASX", {
        contentType: 'static_pages',
        entryId: 'blt9042d4645a7f38ac',
    }],
    ["TSX", {
        contentType: 'static_pages',
        entryId: 'blt43e333b1b77ede84'
    }],
    ["AMEX", {
        contentType: 'static_pages',
        entryId: 'bltc99bc217224777c8'
    }],
    ["BER", {
        contentType: 'static_pages',
        entryId: 'blt06ff832a7d2c414f'
    }],
    ["HAM", {
        contentType: 'static_pages',
        entryId: 'blt24f367ff0fc57cfd'
    }],
    ["XMAD", {
        contentType: 'static_pages',
        entryId: 'blt3c16db6755acbf73'
    }],
    ["MIL", {
        contentType: 'static_pages',
        entryId: 'blt69766b94bd19d8cb'
    }],
    ["BOM", {
        contentType: 'static_pages',
        entryId: 'blt18c323335fd7b8b0'
    }],
    ["DUS", {
        contentType: 'static_pages',
        entryId: 'blt67cfcabb487c8340'
    }],
    ["BATS", {
        contentType: 'static_pages',
        entryId: 'blt359f0a6b339ec957'
    }],
    ["XAMS", {
        contentType: 'static_pages',
        entryId: 'blt55904f4c5f55e03f'
    }],
    ["XBRU", {
        contentType: 'static_pages',
        entryId: 'blte26295ce965461c9'
    }],
    ["XPAR", {
        contentType: 'static_pages',
        entryId: 'blt9ddaa70afaa453db'
    }],
    ["HKSE", {
        contentType: 'static_pages',
        entryId: 'blt94575ee68f0ba483'
    }],
    ["XKRX", {
        contentType: 'static_pages',
        entryId: 'blt9bbe1fa24f221b83'
    }],
    ["LSE", {
        contentType: 'static_pages',
        entryId: 'blt465103663765635a'
    }],
    ["OHEL", {
        contentType: 'static_pages',
        entryId: 'blt447e9108ac9e9d73'
    }],
    ["OSTO", {
        contentType: 'static_pages',
        entryId: 'bltc948528859f5be29'
    }],
    ["NASDAQ", {
        contentType: 'static_pages',
        entryId: 'blt660f43098387f8d0'
    }],
    ["NSE", {
        contentType: 'static_pages',
        entryId: 'blt55c8552b7822c2d7'
    }],
    ["NYSE", {
        contentType: 'static_pages',
        entryId: 'bltfb8a5b8c686fbb43'
    }],
    ["NZSE", {
        contentType: 'static_pages',
        entryId: 'blt74d01128354d324e'
    }],
    ["HAN", {
        contentType: 'static_pages',
        entryId: 'blt96aa796670b7f06a'
    }],
    ["ARCA", {
        contentType: 'static_pages',
        entryId: 'blt53b0d13f52c76362'
    }],
    ["OSL", {
        contentType: 'static_pages',
        entryId: 'blt54f99227e39e474f'
    }],
    ["SHSE", {
        contentType: 'static_pages',
        entryId: 'bltbe78ab8ed39df49d'
    }],
    ["SZSE", {
        contentType: 'static_pages',
        entryId: 'blt34add1175b5990d1'
    }],
    ["SGX", {
        contentType: 'static_pages',
        entryId: 'blt1f6f292641535b48'
    }],
    ["XSWX", {
        contentType: 'static_pages',
        entryId: 'blt16b16d8e480f8731'
    }],
    ["TPE", {
        contentType: 'static_pages',
        entryId: 'blt0a4297c68dbfe214'
    }],
    ["TSE", {
        contentType: 'static_pages',
        entryId: 'bltdc0b091497519405'
    }],
    ["TSX", {
        contentType: 'static_pages',
        entryId: 'blta37df479c948cff0'
    }],
    ["XETR", {
        contentType: 'static_pages',
        entryId: 'blt47c8e245429207e1'
    }],
]);

