import { ActionTree, MutationTree } from 'vuex';
import type { State as RootState } from '~/store';
import ContentStackService from '~/services/content-stack';
import { Content } from '@mca-components-ui/models';
import { SEO } from '@mca-components-ui/models/content';
import { ISEO } from '@mca-components-ui/types/contentstack/api';

export const state = () => ({
    meta: {} as SEO,
});

export interface ISeoHeadItem {
    canonical: string;
    slug_id: string
}

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {
    SET_METADATA : (state: State, metadata: ISEO) => {
        state.meta = new Content.SEO(metadata);
    },
};

export const actions: ActionTree<RootState, State> = {
    async fetchContentHeadElements({ commit }, payload: ISeoHeadItem) {
        const contentService = new ContentStackService();
        const metadata = await contentService.getPageMetadata(payload.slug_id);
        if (metadata) {
            commit('SET_METADATA', metadata);
        }
    }
};