import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { IntradaySecurity } from '~/plugins/intraday-security';


@Module({
    name: 'markets',
    stateFactory: true,
    namespaced: true,
})

export default class markets extends VuexModule {
    intradaySecurities= new Map<string, IntradaySecurity>();
    marketsMovementUpdateTime: Date | null = null;

  @Mutation
    setIntradaySecurity(security: IntradaySecurity) {
        const id = security._storeKey || security.id;
        if(id) {
            this.intradaySecurities.set(id, JSON.parse(JSON.stringify(security)));
        }
    }

  @Mutation
  setMarketsMovementUpdateTime(updateTime: Date) {
      this.marketsMovementUpdateTime = updateTime;
  }
}