import { Plugin } from '@nuxt/types';
import QueryIntraday from '~/services/queries/getIntradaySecurity.gql';
import type { Types as ApiTypes } from '@mca-dfd-api/graphql-schema';

export type IntradaySecurity = {
    id? : string,
    name?: string, 
    timeseries? : Array<ApiTypes.TimeSeriesItem>,
    lastPrice ?: number,
    lastPriceDate?: string,
    previousClose ?: number,
    netChange?: number,
    netChangePercent?: number,
    openTime?: Date | string,
    closeTime?: Date | string,
    isMarketOpen?: boolean,
    updateTime?: Date,
    _storeKey?: string
}
export type IntradaySecurityInput = {
    id: string,
    name?: string,
    frequency: number,
    includeTimeseries?: boolean
}

type IntradaySecurityResponse = {
    timeseries?: ApiTypes.TimeSeriesPrice,
    security?: ApiTypes.Security
}

declare module '@nuxt/types' {
  interface Context {
    $getIntradaySecurity(option:IntradaySecurityInput):  Promise<IntradaySecurity>
  }
}

const intradaySecurity: Plugin = (context) => {
    
    context.$getIntradaySecurity = async (option: IntradaySecurityInput): Promise<IntradaySecurity> => {   
        let secData: IntradaySecurity = getDefaultSecurityData(option.id);
        try{
            const res = await context.app.apolloProvider?.clients['realtime'].query<IntradaySecurityResponse>({
                query: QueryIntraday,
                variables: {
                    id: option.id,
                    frequency: option.frequency,
                    includeTimeseries: typeof(option.includeTimeseries) !== 'undefined' ? option.includeTimeseries : true
                },
                fetchPolicy: "network-only"
            });
            secData = transformToIntradaySecurity(option.id,res!.data);
            if (option.name) {
                secData.name = option.name;                
            }
        }
        catch {
            
        }
        setStoreData(option, secData);
        return secData;
    };

    const setStoreData = (option:IntradaySecurityInput, sec:IntradaySecurity)=>{
        if (process.client) {
            const storeKey = getStoreKey(option);
            localStorage.setItem(storeKey, JSON.stringify(sec));
        }
    };
    
    const getStoreKey = (option: IntradaySecurityInput): string => `${option.id}_${option.frequency}_${option?.includeTimeseries === false ? 'not' : 'is'}Timeseries`;
       
    const getStoreData = (option:IntradaySecurityInput): IntradaySecurity | undefined =>{
        if (process.client) {
            const storeKey = getStoreKey(option);
            try {
                return JSON.parse(localStorage.getItem(storeKey) || '');
            }
            catch{}
        }
    };
    const transformToIntradaySecurity = (id:string,rawData : IntradaySecurityResponse): IntradaySecurity =>{
        if(rawData && (rawData.timeseries || rawData.security)) {            
            const intradaySec: IntradaySecurity = {
                id: rawData.security?.id || id,
                name: rawData.security?.name || '',
                timeseries: Object.assign(rawData.timeseries?.values || []),
                netChange: rawData.security?.intradayPrice?.netChange || undefined,
                netChangePercent: rawData.security?.intradayPrice?.netChangePercent || undefined,
                previousClose: rawData.security?.closePrice || rawData.security?.intradayPrice?.lastClose || undefined,
                lastPrice: rawData.security?.intradayPrice?.price || undefined,
                isMarketOpen: rawData.security?.exchangeInfo?.tradingStatus?.toLowerCase() == "open",
                updateTime: new Date()
            };
            const latestPrice = getLatestPrice(intradaySec.timeseries);
            if(latestPrice?.datetime) { 
                intradaySec.lastPriceDate = context.$dateFns.format(new Date(latestPrice.datetime), 'dd MMM yyyy hh:mm a');
                
                if (intradaySec.isMarketOpen) {
                    if(rawData.security?.exchangeInfo?.openingTime) {
                        intradaySec.openTime = addTimeToDate(latestPrice.datetime,rawData.security?.exchangeInfo.openingTime);
                    }
                    if(rawData.security?.exchangeInfo?.closeTime) {
                        intradaySec.closeTime = addTimeToDate(latestPrice.datetime,rawData.security?.exchangeInfo.closeTime);
                    }
                }
            }
            return Object.assign(intradaySec);
        }
        return getDefaultSecurityData(id);
    };
    const getLatestPrice = (prices?:Array<ApiTypes.TimeSeriesItem>): ApiTypes.TimeSeriesItem | undefined =>{
        if(prices) {
            const latestPriceIdx = prices.length -1;
            if(latestPriceIdx >= 0) {
                return prices[latestPriceIdx];
            }
        }
    };
    const getDefaultSecurityData = (id: string) => {
        
        return {
            id: id,
            timeseries: [],
            previousClose: 0,
            netChange: 0,
            netChangePercent: 0,
            lastPrice: 0
        };
    };
    const addTimeToDate = (priceDate: string | Date,timeToUpdate: string): string | undefined => {
        const datePart = priceDate.toString().split('T');
        if (timeToUpdate.split(":").length == 2 && datePart.length == 2) {
            return `${datePart[0]}T${timeToUpdate}:00`;
        }
    };
    const isDataExpired = (updateTime: Date | string | undefined, frequency: number):boolean =>{
        if (updateTime) {
            const expiryTime = new Date(updateTime).getTime() + ((frequency-1) * 60 * 1000);
            return expiryTime < new Date().getTime();
        }
        return true;
    };
};

export default intradaySecurity;