import { State as RootState } from "~/store";
import { ActionTree, GetterTree, MutationTree } from "vuex";

export type State = ReturnType<typeof state>;

export const state = () => ({
    GUID: undefined as string | undefined,
    additionalParams: {
        country: 'AU'
    } as Record<string, string | undefined>
});

export const mutations: MutationTree<State> = {
    SET_GUID: (state, guid: string) => {
        state.GUID = guid;
    },
};

export const actions: ActionTree<State, State> = {
    async init({ commit }) {

        try {
            const cookies = await this.dispatch('eloqua/getEloquaCookie');
            commit('SET_GUID', cookies['GUID']);
        }
        catch (e) {
            console.error(`Error while reading eloqua cookies: ${e}`);
        }

    },

    async getEloquaCookie(): Promise<Record<string, string>> {

        return new Promise((resolve, reject) => {

            let pollAttempts = 0;
            let cookie = false as boolean | Record<string, string>;

            const readCookie = (name: string) => {
                const eloquaCookie = this.$cookies.get(name);

                if (eloquaCookie) {
                    const parts = eloquaCookie.split('&') as string[];
                    const data = {} as Record<string, string>;

                    parts.forEach(part => {
                        const [name, value] = part.split('=');
                        data[name] = value;
                    });

                    return data;
                }

                return false;
            };

            cookie = readCookie('ELOQUA');

            if (cookie) {
                return resolve(cookie);
            }
            else {
                const pollInterval = setInterval(() => {
                    pollAttempts++;

                    cookie = readCookie('ELOQUA');

                    if (cookie) {
                        clearInterval(pollInterval);
                        return resolve(cookie);
                    }
                    else if (pollAttempts > 20) {
                        reject('Timeout reading cookie');
                        clearInterval(pollInterval);
                    }
                }, 250);
            }
        });
    }
};

export const getters: GetterTree<State, RootState> = {
    GUID: (state) => state.GUID
};