import type { SecurityRequestPayload } from "~/types/investment/ideas";
import { ActionTree } from "vuex";
import { State as RootState } from "~/store";
import UpcomingDividendsService from "~/services/markets/upcomingDividendService";

export type State = ReturnType<typeof state>;

export const state = () => ({});


export const actions: ActionTree<RootState, State> = {
    async fetchSecurities ({}, request: SecurityRequestPayload) {
        const service = new UpcomingDividendsService(this.app.apolloProvider!.defaultClient);
        return await service.getSecurities(request);
    }
};

