import * as LayoutConfig from "~/configs/page-layout";

import { Context } from '@nuxt/types';
import type { Route } from 'vue-router';
import type { Store } from 'vuex';
import type { UserSegment } from '@/configs/user';
import type { State as UserState } from '@/store/user';

const MARKETING_PAGE_ROUTES = ['products-product', 'solutions-solution'];
const SECURITY_TYPE_MAP = new Map([['ST', 'stocks'], ['FE', 'etfs'], ['FO', 'funds'], ['FC', 'lics'], ['XI', 'indices'], ['IX', 'indexes']]);

export default function ({ app, store, route }: Context) {

    const savedAdvertInfo = JSON.parse(localStorage.getItem('advert.splash') || '{}');

    if (savedAdvertInfo.lastViewed) {
        store.commit('adverts/LAST_SAVED_VIEW', new Date(savedAdvertInfo.lastViewed));
    }

    app.router!.afterEach(async (to) => {

        const isNotMarketingPage = !MARKETING_PAGE_ROUTES.includes(to.name!);
        const userSegment = (store.state.user as UserState).segment;
        const isSegmented = userSegment !== 'unknown';

        store.commit("adverts/RESET");

        if (isNotMarketingPage && isSegmented) {

            const isDesktop = window.innerWidth >= LayoutConfig.BREAKPOINTS.large;
            const config = getAdvertConfig(to, store, isDesktop, userSegment);

            store.dispatch("adverts/load", config);
        }
    });

    store.subscribe((mutation) => {
        if (mutation.type === 'user/SET_USER_SEGMENT') {
            const isNotMarketingPage = !MARKETING_PAGE_ROUTES.includes(route.name!);

            store.commit("adverts/RESET");

            if (isNotMarketingPage) {
                const userSegment = mutation.payload;
                const isDesktop = window.innerWidth >= LayoutConfig.BREAKPOINTS.large;
                const config = getAdvertConfig(route, store, isDesktop, userSegment);

                store.dispatch("adverts/load", config);
            }
        }
    });
}

function getAdvertConfig(route: Route, store: Store<any>, isDesktop: boolean, firmType: UserSegment) {
    return {
        routeName: route.name,
        isDesktop,
        customTags: {
            ...route.params,
            firmType,
            ...route.name === 'insights-category-id-slug' || route.name === 'mca-article' ? {
                keywords: store.state.document.article.keywords.map((keyword: string) => keyword.replace(' ', '')),
                pgid: route.params.id,
                collection: route.params.category,
                area: 'insights'
            } : {},
            ...route.name === 'insights-category' ? {
                pgid: route.params.category,
                collection: route.params.category,
                area: 'insights'
            } : {},
            ...route.name === 'investments-security-exchangeCode-symbol-view' || route.name === 'mca-security' ? {
                securityType: store.state.investments.security.securityType,
                view: route.params.view ?? 'quote',
                pgid: route.params.view ?? 'quote',
                ticker: route.params.symbol,
                collection: SECURITY_TYPE_MAP.get(store.state.investments.security.securityType),
                area: 'investments'
            } : {},
            ...route.name === 'index' ? {
                area: 'homepage',
                collection: 'homepage'
            } : {},
            ...route.name === 'markets-upcoming-dividends' ? {
                collection: 'upcoming-dividends'
            } : {},
            ...route.name === 'search-type-term' ? {
                collection: route.params.type
            } : {},
            ...route.name === 'mca-learn-article' ? {
                keywords: store.state.document.article.keywords.map((keyword: string) => keyword.replace(' ', '')),
                pgid: route.params.id,
                collection: 'investing',
                area: 'learn'
            } : {},
            ...route.name === 'sitemap' || route.name === 'sitemap-year' || route.name === 'sitemap-year-month' ? {
                collection: 'news',
                area: 'sitemap'
            } : {},
            ...route.name === 'mca-search' ? {
                collection: route.params.type == 'documents' ? 'news' : SECURITY_TYPE_MAP.get(route.params.securityType),
                area: 'search'
            } : {},
            ...route.name === 'search-type' ? {
                collection: route.params.type == 'documents' ? 'news' : route.params.type,
                area: 'search'
            } : {},
        }
    };
}