import type { MDS } from "@mca-components-ui/types";

export const SECURITY_TYPE_FILTER = [
    { value: "ST", text: "Stocks" },
    { value: "FO", text: "Funds" },
    { value: "FE", text: "ETFs" },
    { value: "FC", text: "LICs" }
];

export const INVESTMENT_STRATEGY_FILTER = [
    { value: "IS_AUI", text: "Australian Investing" },
    { value: "IS_GLI", text: "Global Investing" },
    { value: "IS_INI", text: "Income Investing" },
    { value: "IS_ACI", text: "Active Investing" },
    { value: "IS_PAI", text: "Passive Investing" },
    { value: "IS_SUI", text: "Sustainable Investing" },
];

export const ASSET_ALLOCATION_FILTER = [
    { value: "AA_AUE", text: "Australian Equity" },
    { value: "AA_GLE", text: "Global Equity" },
    { value: "AA_ALP", text: "Australian Listed Property" },
    { value: "AA_GLP", text: "Global Listed Property" },
    { value: "AA_EME", text: "Emerging Market Equity" },
    { value: "AA_AFI", text: "Australian Fixed Interest" },
    { value: "AA_GFI", text: "Global Fixed Interest" },
    { value: "AA_GLI", text: "Global Infrastructure" },
    { value: "AA_MUS", text: "Multi Sector" },
];

export const RESEARCH_METHODOLOGIES_TITLE = "Our research methodologies";

export const SCREENER_TABS = [{
    id: "assetAllocation",
    text: "Asset Allocation",
    type: ["ST"],
    fields: ["Name", "ExchangeCode", "SectorName", "IndustryName", "Intraday.Price", "MarketCap", "EquityStyleBox"]
},
{
    id: "income",
    text: "Income",
    type: ["ST"],
    fields: ["Name", "DividendYield", "PayoutRatio", "FrankedRate"]
},
{
    id: "financialStrength",
    text: "Financial strength",
    type: ["ST"],
    fields: ["Name", "DebtEquityRatio", "InterestCoverage", "QuickRatio"]
},
{
    id: "valuation",
    text: "Valuation",
    type: ["ST"],
    fields: ["Name", "PERatio", "PCF", "PBRatio"]
},
{
    id: "quality",
    text: "Quality",
    type: ["ST"],
    fields: ["Name", "ROETTM", "ROATTM", "NetMargin"]
},
{
    id: "growthPerformance",
    text: "Growth & performance",
    type: ["ST"],
    fields: ["Name", "RevenueGrowth3Y", "EPSGrowth3YYear1", "ReturnM0", "ReturnM12", "ReturnM36", "ReturnM60", "ReturnM120"]
},
{
    id: "overviewFunds",
    text: "Overview",
    type: ["FO"],
    fields: ["Name", "SustainabilityRank", "EquityStyleBox", "BondStyleBox", "ClosePrice"]
},
{
    id: "overview",
    text: "Overview",
    type: ["FE", "FC"],
    fields: ["Name", "SustainabilityRank", "EquityStyleBox", "BondStyleBox", "Intraday.Price"]
},
{
    id: "CategoryAndStyle",
    text: "Category and Style",
    type: ["FO", "FE", "FC"],
    fields: ["Name", "CategoryName", "IndexFund"]
},
{
    id: "yieldCostPerformance",
    text: "Yield, Cost & Performance",
    type: ["FO", "FE", "FC"],
    fields: ["Name", "Yield_M12", "MorningstarTotalCostRatioPDS", "ReturnM0_ME", "ReturnM12_ME", "ReturnM36_ME", "ReturnM60_ME", "ReturnM120_ME"]
}
];

export const GetScreenerTabs = (securityType: string, activeTabId?: string): MDS.ButtonGroup.IButtonGroupContent[] => {
    const tabs: MDS.ButtonGroup.IButtonGroupContent[] = [];
    for (const tab of SCREENER_TABS) {
        if (tab.type.includes(securityType)) {
            tabs.push({
                id: tab.id,
                text: tab.text,
                active: tab.id == activeTabId
            });
        }
    }
    if (!activeTabId) {
        tabs[0].active = true;
    }
    return tabs;
};