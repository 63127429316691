import { render, staticRenderFns } from "./_nav-list.vue?vue&type=template&id=282e23b4&scoped=true"
import script from "./_nav-list.vue?vue&type=script&lang=ts"
export * from "./_nav-list.vue?vue&type=script&lang=ts"
import style1 from "./_nav-list.vue?vue&type=style&index=1&id=282e23b4&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "282e23b4",
  null
  
)

export default component.exports