import { ApolloClient } from 'apollo-client';
import type { DocumentNode } from 'graphql';
import type { Types as ApiTypes } from "@mca-dfd-api/graphql-schema";
import ScreenerSecurity from "~/models/screenerSecurity";
import type { ScreenerSecuritiesResponse, SecurityRequestPayload } from "~/types/investment/ideas";
    

export default class ScreenerSecuritiesService {
    constructor(public gqlClient: ApolloClient<any>, public query: DocumentNode ) { }
    
    async getSecurities(request: SecurityRequestPayload): Promise<ScreenerSecuritiesResponse> {
        const res = await this.gqlClient.query({
            query: this.query,
            variables: request
        });
        return this.formatScreenerResults(res.data.screener);
    }

    private formatScreenerResults(results: ApiTypes.PageScreenerSecurity): ScreenerSecuritiesResponse {
        const securities: ScreenerSecurity[] = [];
        results.securities?.forEach((sec) => {
            securities.push(new ScreenerSecurity(sec as ApiTypes.ScreenerSecurity));
        });
        return {
            page: results.page,
            pageSize: results.pageSize,
            total: results.total || 0,
            securities: securities
        } as ScreenerSecuritiesResponse;
    }
}
