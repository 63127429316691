// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mca-accordion-nav .mds-list-group__toggle__mca-dfd{flex-direction:row-reverse;justify-content:space-between}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mds-list-group__toggle": "mds-list-group__toggle__mca-dfd"
};
module.exports = ___CSS_LOADER_EXPORT___;
