import { ActionTree, MutationTree } from "vuex";
import MarketReportService from "~/services/markets/marketReportService";
import type { State as RootState } from "~/store";
import { Types as ApiTypes } from "@mca-dfd-api/graphql-schema";


export const state = () => ({
    globalMarketReport: {
        id: "",
        title: "",
        fullTitle: "",
        createdDate: "",
        description: "",
        body: '',
        primaryAuthor: {} as ApiTypes.Author,
        markets: [{
            id: "",
            title: "",
            body: ""
        }] as ApiTypes.Market[]
    } as ApiTypes.GlobalMarketData,
    priceToFairValue: {
        australia: {
            countries: [] as Array<ApiTypes.Country>
        } as ApiTypes.Region,
        asia: {
            countries: [] as Array<ApiTypes.Country>
        } as ApiTypes.Region,
        europe: {
            countries: [] as Array<ApiTypes.Country>
        } as ApiTypes.Region,
        northAmerica: {
            countries: [] as Array<ApiTypes.Country>
        } as ApiTypes.Region,
    } as ApiTypes.RegionMap,
    globalMarketData: {
        equity: [] as Array<ApiTypes.MarketIndex>,
        currency: [] as Array<ApiTypes.CurrencyExchange>,
        commodity: [] as Array<ApiTypes.Commodity>,
    } as ApiTypes.MarketData
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {
    SET_GLOBAL_MARKET_REPORT: (state: State, globalMarketReport: ApiTypes.GlobalMarketData) => {
        state.globalMarketReport = globalMarketReport;
    },
    SET_PRICE_FAIR_VALUE: (state: State, priceFairValue: ApiTypes.RegionMap) => {
        state.priceToFairValue = priceFairValue;
    },
    SET_GLOBAL_MARKET_DATA: (state: State, marketData: ApiTypes.MarketData) => {
        state.globalMarketData = marketData;
    },
};

export const actions: ActionTree<RootState, State> = {
    async fetchLatest({ commit }) {
        const globalMarket = new MarketReportService(this.app.apolloProvider!.defaultClient);
        const { data } = await globalMarket.getGlobalMarketReportData();

        commit("SET_GLOBAL_MARKET_REPORT", data.getGlobalMarketReport);
    },
    async fetchPriceFairValue({ commit }) {
        const priceToFairValue = new MarketReportService(this.app.apolloProvider!.defaultClient);
        const { data } = await priceToFairValue.getPriceToFairValueData();

        commit("SET_PRICE_FAIR_VALUE", data.getPriceFairValue);
    },
    async fetchMarketData({ commit }) {
        const globalMarketData = new MarketReportService(this.app.apolloProvider!.defaultClient);
        const { data } = await globalMarketData.getGlobalMarketData();

        commit("SET_GLOBAL_MARKET_DATA", data.globalMarketData);
    },
};
