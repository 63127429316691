import { render, staticRenderFns } from "./UserTypeSelection.vue?vue&type=template&id=18220ca3&scoped=true"
import script from "./UserTypeSelection.vue?vue&type=script&lang=ts"
export * from "./UserTypeSelection.vue?vue&type=script&lang=ts"
import style0 from "./UserTypeSelection.vue?vue&type=style&index=0&id=18220ca3&prod&lang=scss&scoped=true"
import style1 from "./UserTypeSelection.vue?vue&type=style&index=1&id=18220ca3&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "18220ca3",
  null
  
)

export default component.exports