import { AD_SIZES, AdvertPlacement, COMMON_ADVERT_PLACEMENTS, ROUTE_ADVERT_PLACEMENTS, SITE } from '~/configs/adverts';

import type { MCA } from '@mca-components-ui/types';

class AdvertsService {

    constructor(public routeName: string, public tags: Record<string, string>, public isDesktop: boolean, public isSplashViewable: boolean) { }
    /**
     * GetAdverts, fetches the adverts from the given URL
     * @returns {Promise<Map<AdvertPlacement, string>>} A mapping of valid AdvertPlacement and the related string
     */
    public async getAdverts(): Promise<Map<AdvertPlacement, string>> {

        let placements = [] as [AdvertPlacement, string][];

        try {
            const request = new Request(this.url);
            const response = await fetch(request);
            const result = await (response.ok ? response.json() : Promise.reject(response.statusText)) as string[];
            placements = this.routePlacements
                .map((placementName, index) => [placementName, result[index]] as [AdvertPlacement, string])
                .filter(([, advert]) => !this.isEmptyAdvert(advert));
        }
        catch (e) {
            console.error('Error loading adverts:', e);
        }

        return new Map(placements);
    }

    get url(): string {
        const { keywords, ...restTags } = this.tags;
        const queryParams = {
            collection: this.routeName.split('-')[0],
            area: this.routeName.split('-')[0],
            random: Date.now(),
            viewId: Date.now(),
            ...this.getTopics(keywords as unknown as string[] ?? []),
            ...restTags
        } as Record<string, string | number>;

        const queryString = Object.keys(queryParams).map(key => '/' + key + '=' + queryParams[key]).join('');
        const advertServer = process.env.AD_SERVER + '=' + SITE;

        return advertServer + queryString + this.bundleAdSegments;
    }

    get bundleAdSegments(): string {
        return Array.from(this.placements).map(([, placementName], idx) => '/b' + (idx + 1) + '/' + placementName).join('');
    }

    get placements(): Map<AdvertPlacement, string> {
        return new Map(this.routePlacements.map(placementName => [placementName, this.getPlacementSegment(placementName)]));
    }

    get routePlacements(): AdvertPlacement[] {
        const routeBasedPlacements = ROUTE_ADVERT_PLACEMENTS.get(this.routeName);

        if (routeBasedPlacements) {
            return [...routeBasedPlacements(this.isDesktop), ...COMMON_ADVERT_PLACEMENTS(this.isSplashViewable)];
        }
        else {
            return COMMON_ADVERT_PLACEMENTS(this.isSplashViewable);
        }
    }

    /**
     * Tests if the given advert HTML contains an empty advertisement
     * @param advertHTML response HTML from advert server
     * @return true if contains an empty advert e.g. <img src="http://content.aimatch.com/empty.gif"
     */
    isEmptyAdvert(advertHTML: string | undefined): boolean {
        return !advertHTML || advertHTML.includes('//content.aimatch.com/empty.gif');
    }
    /**
     * Generates a topic object with the given keywords
     * @param {string[]} keywords - An array of strings to generate topics from
     * @returns {Record<string, string>} - An object containing the generated topics
     */
    private getTopics(keywords: string[]): Record<string, string> {
        return Object.fromEntries(keywords.map((word, index) => index == 0 ? ['topic', word.split(' ').join('')] : ['subtopic' + index, word.split(' ').join('')]));
    }
    // Gets the Placement segment value, which is composed of the size segment and the position segment, for the specified Advert Placement.
    private getPlacementSegment(placementName: AdvertPlacement): string {
        return this.getSizeSegment(this.getPlacementSizes(placementName)) + '/' + this.getPosSegment(placementName);
    }
    /**
     * Returns the advert sizes for the provided placement depending on the device type.
     * @param {AdvertPlacement} placementName The placement name
     * @returns {MCA.Adverts.AdvertSize[]} The advert sizes
     */
    private getPlacementSizes(placementName: AdvertPlacement): MCA.Adverts.AdvertSize[] {
        return this.isDesktop ? AD_SIZES[placementName].sizes.desktop : AD_SIZES[placementName].sizes.mobile;
    }
    // Gets the segment containing details associated with the available advert sizes (eg. "size=300x250,320x320")
    private getSizeSegment(sizes: MCA.Adverts.AdvertSize[]): string {
        return `size=${sizes.length ? sizes.map(size => size.width + 'x' + size.height).join(',') : 'dynamicoffer'}`;
    }
    //This function will return a string representing the "pos" segment for an advert placement, based on the given placement name.
    private getPosSegment(placementName: AdvertPlacement): string {
        return 'pos=' + AD_SIZES[placementName]['position'];
    }
}

export default AdvertsService;
