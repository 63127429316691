import { PLACEHOLDERS } from "~/configs/images";
import { IAuthor } from '~/types/author/author';
import { IPagedResponse } from '~/types/author/author';
export class AuthorResult {
    public id:              string;
    public name:            string;
    public email:           string;
    public byLine:          string;
    public jobTitle:        string;
    public shortBio:        string;
    public longBio:         string;
    public imageUrl:        string;
    public twitterHandle:   string;
    public documents:       IPagedResponse;

    constructor(author: IAuthor) {

        this.id             = author.id;
        this.name           = author.name;
        this.email          = author.email;
        this.byLine         = author.byLine;
        this.jobTitle       = author.jobTitle;
        this.shortBio       = author.shortBio;
        this.longBio        = author.longBio;
        this.imageUrl       = author.imageUrl ? author.imageUrl: PLACEHOLDERS.avatar;
        this.twitterHandle  = author.twitterHandle;
        this.documents      = author.documents;
    }
}

export default AuthorResult;