import * as Constants from "~/configs/page-layout";

import { GetterTree, MutationTree } from "vuex";

import { State as RootState } from "~/store";

type SizeType = {
    width: number;
    height: number;
};

export const state = () => ({
    size: {
        width: 1920, 
        height: 1200
    } as SizeType
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {
    WINDOW_RESIZE: (state: State, size: SizeType) => {
        state.size = size;
    }
};

export const getters: GetterTree<State, RootState> = {
    windowWidth: state => state.size.width,
    windowHeight: state => state.size.height,
    isMobileViewport: state => state.size.width <= Constants.BREAKPOINTS.medium,
    isTabletViewport: state => state.size.width < Constants.BREAKPOINTS.extraLarge && state.size.width > Constants.BREAKPOINTS.medium,
    isDesktopViewport: state => state.size.width >= Constants.BREAKPOINTS.extraLarge,
};
