import Vue from 'vue'
import { createImage} from '~image'
import NuxtImg from '~image/components/nuxt-img.vue'
import NuxtPicture from '~image/components/nuxt-picture.vue'

import * as staticRuntime$3443 from '/user/src/app/app/node_modules/@nuxt/image/dist/runtime/providers/static.js'
import * as ipxRuntime$c7c6 from '/user/src/app/app/node_modules/@nuxt/image/dist/runtime/providers/ipx.js'

const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "avatarSm": {
      "modifiers": {
        "format": "webp",
        "width": 30,
        "height": 30,
        "quality": 100
      }
    },
    "cardSm": {
      "modifiers": {
        "format": "webp",
        "width": 90,
        "height": 84,
        "quality": 100
      }
    },
    "cardMd": {
      "modifiers": {
        "format": "webp",
        "quality": 100
      }
    },
    "marketingCtaCard": {
      "modifiers": {
        "format": "webp",
        "width": 64,
        "height": 64,
        "quality": 100,
        "lazy": true
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "im.morningstar.com",
    "video.morningstar.com",
    "cdn.morningstar.com.au",
    "www.morningstar.com.au",
    "morningstar.co.uk",
    "www.morningstar.co.uk",
    "images.contentstack.io",
    "cloudfront-us-east-1.images.arcpublishing.com",
    "premium.morningstar.com.au"
  ],
  "alias": {}
}

imageOptions.providers = {
  ['static']: { provider: staticRuntime$3443, defaults: {} },
  ['ipx']: { provider: ipxRuntime$c7c6, defaults: {} }
}

Vue.component(NuxtImg.name, NuxtImg)
Vue.component(NuxtPicture.name, NuxtPicture)
Vue.component('NImg', NuxtImg)
Vue.component('NPicture', NuxtPicture)

export default function (nuxtContext, inject) {
  const $img = createImage(imageOptions, nuxtContext)

  if (process.static && process.server) {
    nuxtContext.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._img = nuxtState._img || {}
    })
  }

  inject('img', $img)
}
