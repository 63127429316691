import { ActionTree, MutationTree } from "vuex";
import AuthorService from "~/services/author/author";
import { State as RootState } from "~/store";
import {IAuthor} from "~/types/author/author";
import { AuthorResult } from "~/models/author";
import { Content } from "@mca-components-ui/models";

export const state = () => ({
    authorData: {
    } as IAuthor,
    documents: [] as Content.Document[]
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {
    SET_AUTHOR_DATA: (state: State, authorData: IAuthor) => {
        state.authorData = authorData;
    },
    SET_DOCUMENT_DATA: (state: State, documents: Content.Document[])=> {
        state.documents = documents;
    }
};

export const actions: ActionTree<RootState, State> = {
    async fetchLatest({ commit }, payload: IAuthor) {
        const service = new AuthorService(this.app.apolloProvider!.defaultClient);
        const authorResponse = await service.getAuthor(payload);
        const authorData = new AuthorResult(authorResponse.data.getAuthor);
        commit("SET_AUTHOR_DATA", authorData);
        commit("SET_DOCUMENT_DATA", authorData.documents.documents);
    }
};
