import { ActionTree, MutationTree, GetterTree } from "vuex";
import { State as RootState } from "~/store";
import MaasService, { TokenResponse } from '~/services/maasService';

export const state = () => ({
    maasToken: {} as TokenResponse
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {

    UPDATE_MAAS_TOKEN: (state, maasToken: TokenResponse) => {
        state.maasToken = maasToken;
    }
};

export const actions: ActionTree<RootState, State> = {
    async getMaasToken({ commit, getters }) {
        const maasService = new MaasService();
        const accessToken = getters.token;
        try {
            const accessTokenValid = !!accessToken && !maasService.isTokenExpired(
                maasService.decodeMaasToken(accessToken)
            );
            if (accessTokenValid) {
                return accessToken;
            }
            const response = await maasService.getToken(this.$axios);

            commit("UPDATE_MAAS_TOKEN", response);
            return response.access_token;
        }
        catch (err) {
            console.error('error occured in getMaasToken...', err);
            throw err;
        }
    }
};

export const getters: GetterTree<State, RootState> = {
    token: state => state.maasToken.access_token
};