import { ActionTree, MutationTree } from 'vuex';

import type {MCA} from '@mca-components-ui/types';
import { ILink } from '@mca-components-ui/explore';
import type {State as RootState} from '~/store';
import { getStack } from '~/services/content-stack/stack';

export const state = () => ({
    topStories: [] as MCA.Models.Contentstack.ITopStories[],
    webinars: [] as MCA.Models.Contentstack.IWebinar[],
    specialists: [] as any[],
    specialTopics: [] as any[],
    podcasts: [] as any[],
    learnToInvest: [] as any[],
    explore: [] as ILink[]
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {
    SET_TOP_STORIES: (state, topStories: MCA.Models.Contentstack.ITopStories[]) => {
        state.topStories = topStories;
    },
    SET_WEBINARS: (state, webinars: MCA.Models.Contentstack.IWebinar[]) => {
        state.webinars = webinars;
    },
    SET_SPECIALISTS: (state, specialists: any[]) => {
        state.specialists = specialists;
    },
    SET_EXPLORE: (state, explore: Array<{link: ILink}>) => {
        state.explore = explore?.map(item=> item.link);
    },
    SET_SPECIAL_TOPICS: (state, specialTopics: any[]) => {
        state.specialTopics = specialTopics;
    },
    SET_PODCASTS: (state, podcasts: any[]) => {
        state.podcasts = podcasts;
    },
    SET_LEARN_TO_INVEST: (state, learnToInvest: any[]) => {
        state.learnToInvest = learnToInvest;
    }
};

export const actions: ActionTree<RootState, State> = {
    async getTopStoriesFromCS({ commit }) {
        const response = await getStack()
            .ContentType('home_page_top_stories')
            .Entry('blt25748bc9ce326aaa').fetch();
        commit('SET_TOP_STORIES', response.toJSON().top_stories_lineup);
    },

    async getWebinarsFromCS({ commit }) {
        const response = await getStack()
            .ContentType('home_page_webinars')
            .Entry('blt0a9d37a84826b8bf').fetch();
        commit('SET_WEBINARS', response.toJSON().webinars_lineup);
    },

    async getSpecialistsFromCS({ commit }) {
        const response = await getStack()
            .ContentType('specialists_stories')
            .Entry('blt8974f5abc490afb1').fetch();
        commit('SET_SPECIALISTS', response.toJSON().top_stories_lineup);
    },

    async getExploreFromCS({ commit }) {
        const response = await getStack()
            .ContentType('home_page_explore')
            .Entry('blt9eee132a0c0c9eef').fetch();
        commit('SET_EXPLORE', response.toJSON().items);
    },

    async getSpecialTopicsFromCS({ commit }) {
        const response = await getStack()
            .ContentType('special_topics_stories')
            .Entry('bltf4eb8cc39040bcee').fetch();
        commit('SET_SPECIAL_TOPICS', response.toJSON());
    },

    async getPodcastsFromCS({ commit }) {
        const response = await getStack()
            .ContentType('home_page_podcasts')
            .Entry('blt45779a0538a594bb').fetch();
        commit('SET_PODCASTS', response.toJSON());
    },

    async getLearnToInvestFromCS({ commit }) {
        const response = await getStack()
            .ContentType('home_page_learn_to_invest')
            .Entry('bltac7d54f8e5fde8cb').fetch();
        commit('SET_LEARN_TO_INVEST', response.toJSON());
    }
};