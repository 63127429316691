import GetTopicDocuments from "~/services/queries/getTopicDocuments.gql";
import { ApolloClient } from 'apollo-client';
import { TopicCollectionResponse, ITopicPayload } from "~/types/document/topic";

class TopicService {
    constructor(public gqlClient: ApolloClient<any>) {}
    async getTopics(payload: ITopicPayload) {
        return this.gqlClient.query<TopicCollectionResponse>({
            query: GetTopicDocuments,variables:{
                topicKeyword:payload.topicKeyword,
                page: payload.page,
                pageSize: payload.pageSize
            }
        });
    }
}
export default TopicService;
