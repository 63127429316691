import { Types as ApiTypes } from "@mca-dfd-api/graphql-schema";

export class ScreenerSecurity {
    Id: string;
    SecId: string;
    Name: string;
    "Intraday.Price"?: number;
    "Intraday.NetChange"?: number;
    "Intraday.NetChangePercent"?: number;
    FundShareClassId?: string;
    PerformanceId?: string;
    APIR?: string;
    RealtimeId?: string;
    Ticker?: string;
    InvestmentType?: string;
    SecurityType?: string;
    HoldingTypeId?: number;
    Currency?: string;
    ClosePrice?: number;
    ExchangeId?: string;
    ExchangeCode?: string;
    BondStyleBox?: number;
    CategoryName?: string;
    DebtEquityRatio?: number;
    DividendYield?: number;
    EPSGrowth3YYear1?: number;
    EquityStyleBox?: number;
    FrankedRate?: number;
    IndexFund?: boolean;
    IndustryId?: string;
    IndustryName?: string;
    InitialPurchaseBase?: number;
    InitialPurchaseBaseCurrency?: string;
    InterestCoverage?: number;
    IsAUSFlagshipFund?: boolean;
    IsAUSPlatformFund?: boolean;
    IsResearchMainShareclass?: boolean;
    ManagementFee?: number;
    MarketCap?: number;
    MorningstarTotalCostRatioPDS?: number;
    NetMargin?: number;
    OngoingCharge?: number;
    PayoutRatio?: number;
    PBRatio?: number;
    PCF?: number;
    PERatio?: number;
    PriceCurrency?: string;
    QuickRatio?: number;
    ReturnM0?: number;
    ReturnM0_ME?: number;
    ReturnM1?: number;
    ReturnM12?: number;
    ReturnM12_ME?: number;
    ReturnM120?: number;
    ReturnM120_ME?: number;
    ReturnM3?: number;
    ReturnM3_ME?: number;
    ReturnM36?: number;
    ReturnM36_ME?: number;
    ReturnM6?: number;
    ReturnM6_ME?: number;
    ReturnM60?: number;
    ReturnM60_ME?: number;
    RevenueGrowth3Y?: number;        
    ROATTM?: number;
    ROETTM?: number;
    SectorId?: string;
    SectorName?: string;
    SustainabilityRank?: number;
    SustainableInvestmentOverall?: boolean;
    Yield_M12?: number;
    PayDate? : string;
    DivCashAmount?: number;
    ExDate?: string;

    constructor(inputSec: ApiTypes.ScreenerSecurity) {
        this.Id = inputSec.id || "";
        this.SecId = inputSec.secId || "";
        this.Name = inputSec.name || "";
        this.SecurityType = inputSec.securityType?.toString();
        if (inputSec.intradayPrice) {
            this["Intraday.Price"] = inputSec.intradayPrice?.price || undefined;
            this["Intraday.NetChange"] = inputSec.intradayPrice?.netChange || undefined;
            this["Intraday.NetChangePercent"] = inputSec.intradayPrice?.netChangePercent || undefined;
        }
        this.FundShareClassId= inputSec.fundShareClassId || undefined;
        this.PerformanceId= inputSec.performanceId || undefined;
        this.APIR= inputSec.apir || undefined;
        this.RealtimeId= inputSec.realtimeId || undefined;
        this.Ticker= inputSec.ticker || inputSec.symbol || undefined;
        this.InvestmentType= inputSec.investmentType || undefined;
        this.SecurityType= inputSec.securityType || undefined;
        this.HoldingTypeId= inputSec.holdingTypeId || undefined;
        this.Currency= inputSec.currency || undefined;
        this.ClosePrice= inputSec.closePrice || undefined;
        this.ExchangeId= inputSec.exchangeId || undefined;
        this.ExchangeCode= inputSec.exchangeCode || undefined;
        this.BondStyleBox= inputSec.bondStyleBox || undefined;
        this.CategoryName= inputSec.categoryName || undefined;
        this.DebtEquityRatio= inputSec.debtEquityRatio || undefined;
        this.DividendYield= inputSec.dividendYield || undefined;
        this.EPSGrowth3YYear1= inputSec.EPSGrowth3YYear1 || undefined;
        this.EquityStyleBox= inputSec.equityStyleBox || undefined;
        this.FrankedRate= (inputSec.frankedRate === 0 || inputSec.frankedRate ) ? inputSec.frankedRate : undefined;
        this.IndexFund= inputSec.indexFund || undefined;
        this.IndustryId= inputSec.industryId || undefined;
        this.IndustryName= inputSec.industryName || undefined;
        this.InitialPurchaseBase= inputSec.initialPurchaseBase || undefined;
        this.InitialPurchaseBaseCurrency= inputSec.initialPurchaseBaseCurrency || undefined;
        this.InterestCoverage= inputSec.interestCoverage || undefined;
        this.IsAUSFlagshipFund= inputSec.isAUSFlagshipFund || undefined;
        this.IsAUSPlatformFund= inputSec.isAUSPlatformFund || undefined;
        this.IsResearchMainShareclass= inputSec.isResearchMainShareclass || undefined;
        this.ManagementFee= inputSec.managementFee || undefined;
        this.MarketCap= inputSec.marketCap || undefined;
        this.MorningstarTotalCostRatioPDS= inputSec.morningstarTotalCostRatioPDS || undefined;
        this.NetMargin= inputSec.netMargin || undefined;
        this.OngoingCharge= inputSec.ongoingCharge || undefined;
        this.PayoutRatio= inputSec.payoutRatio || undefined;
        this.PBRatio= inputSec.PBRatio || undefined;
        this.PCF= inputSec.PCF || undefined;
        this.PERatio= inputSec.PERatio || undefined;
        this.PriceCurrency= inputSec.priceCurrency || undefined;
        this.QuickRatio= inputSec.quickRatio || undefined;
        this.ReturnM0= inputSec.returnM0 || undefined;
        this.ReturnM0_ME= inputSec.returnM0_ME || undefined;
        this.ReturnM1= inputSec.returnM1 || undefined;
        this.ReturnM12= inputSec.returnM12 || undefined;
        this.ReturnM12_ME= inputSec.returnM12_ME || undefined;
        this.ReturnM120= inputSec.returnM120 || undefined;
        this.ReturnM120_ME= inputSec.returnM120_ME || undefined;
        this.ReturnM3= inputSec.returnM3 || undefined;
        this.ReturnM3_ME= inputSec.returnM3_ME || undefined;
        this.ReturnM36= inputSec.returnM36 || undefined;
        this.ReturnM36_ME= inputSec.returnM36_ME || undefined;
        this.ReturnM6= inputSec.returnM6 || undefined;
        this.ReturnM6_ME= inputSec.returnM6_ME || undefined;
        this.ReturnM60= inputSec.returnM60 || undefined;
        this.ReturnM60_ME= inputSec.returnM60_ME || undefined;
        this.RevenueGrowth3Y= inputSec.revenueGrowth3Y || undefined;
        this.ROATTM= inputSec.ROATTM || undefined;
        this.ROETTM= inputSec.ROETTM || undefined;
        this.SectorId= inputSec.sectorId || undefined;
        this.SectorName= inputSec.sectorName || undefined;
        this.SustainabilityRank= inputSec.sustainabilityRank || undefined;
        this.SustainableInvestmentOverall= inputSec.sustainableInvestmentOverall || undefined;
        this.Yield_M12= inputSec.yield_M12 || undefined;
        this.PayDate= inputSec.payDate || undefined;
        this.DivCashAmount= inputSec.divCashAmount || undefined;
        this.ExDate= inputSec.exDate || undefined;
    }
}

export default ScreenerSecurity;