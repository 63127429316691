
import Vue from 'vue';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default Vue.extend({
    props: ['error'],
    data() {
        return {
            
        };
    },
    components: {
        MdsLayoutGrid, MdsRow, MdsCol
    }
});
