import { ActionTree, MutationTree } from "vuex";
import EloquaFormService from "~/services/eloqua/form";
import {Forms} from '@mca-components-ui/models';
import { Types as McaApiType } from '@mca-dfd-api/graphql-schema';

export type State = ReturnType<typeof state>;

export const state = () => ({
    configs: [] as Forms.Eloqua.EloquaForm[],
    response: undefined as McaApiType.EloquaFormSubmitResponse | undefined
});

export const mutations: MutationTree<State> = {
    SET_CONFIG: (state, form: Forms.Eloqua.EloquaForm) => {
        const formAt = state.configs.findIndex(f => f.id == form.id);
        if(formAt>=0) {
            state.configs[formAt] = form;
        }
        else{
            state.configs.push(form);
        }
    },
    SET_RESPONSE: (state, resp: McaApiType.EloquaFormSubmitResponse | undefined) => {
        state.response = resp;
    }
};

export const actions: ActionTree<State, State> = {

    async load({ commit, rootGetters }, id: string) {
        if(!id || id.length ==0) {
            return;
        }
        const eloqua = new EloquaFormService(this.app.apolloProvider!.defaultClient);
        const formData  = await eloqua.getForm(id, rootGetters["eloqua/GUID"]);

        const eloquaForm = new Forms.Eloqua.EloquaForm(formData);

        commit('SET_CONFIG', eloquaForm);
    },

    async submit({ commit, rootGetters }, {id, data}) {

        const eloqua = new EloquaFormService(this.app.apolloProvider!.defaultClient);
        const response = await eloqua.submitForm(id, data, rootGetters["eloqua/GUID"]);
        commit('SET_RESPONSE', response);
        return response;
    },
};