export const SMALL_COMPONENT_HEIGHT = 150;
export const MOBILE_VIEWPORT_WIDTH = 600;
export const TABLET_VIEWPORT_WIDTH = 1000;

export const BREAKPOINTS = {
    small: 600,
    medium: 768,
    large: 1000,
    extraLarge: 1200
};
