import { rest, graphql } from 'msw';
import { buildSchema } from 'graphql';
import EloquaFormMock from './forms/eloqua.json';
import STOCKS from './getNews/STOCKS.json';
import TOP_STORIES from './getNews/TOP_STORIES.json';

const mockData = {
    getForm: {
        EloquaFormMock
    },
    getNews: {
        TOP_STORIES,
        STOCKS
    }
};

const schema = buildSchema(`
  type ElementInput {
    value: String
  }
`);

export const handlers = [

    rest.get('/api/test', (req, res, ctx) => {

        return res(
            ctx.status(200),
            ctx.json({
                status: 'MSW Response OK',
            }),
        );
    }),

    rest.post('/api/eloqua', (req, res, ctx) => {

        return res(
            ctx.status(200),
            ctx.delay(2000),
            ctx.json({
                status: 'success',
            }),
        );
    }),

    graphql.query('getNews', (req, res, ctx) => {

        const features = localStorage.getItem('features') || [];

        if(features.includes('msw')) {

            if(req.variables.keyword === 'STOCKS') {
                return res(
                    ctx.delay(1000),
                    ctx.data(mockData.getNews.STOCKS),
                );
            }
        }

        return req.passthrough();
    })
];
