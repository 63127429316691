import * as Types from '@mca-components-ui/contentstack-common/types';

import { ActionTree, MutationTree, GetterTree } from 'vuex';

import BestIdeasSection from '~/models/bestIdeasSection';
import ContentStackService from '~/services/content-stack';
import type { IBestIdeasSection } from '~/types/investment/ideas';
import { IDisclaimerPropData } from '@mca-components-ui/contentstack-common/types';
import { RESEARCH_METHODOLOGIES_TITLE } from "~/configs/investment-ideas";
import type { State as RootState } from '~/store';
import { UserSegment } from '@/configs/user';
import  { IColumn } from '@mca-components-ui/footer-v2';
import FooterResult from '~/models/footer';
import type {NewsletterCtaPlacement} from '@/services/content-stack/types';

export type State = ReturnType<typeof state>;

export type NewsletterCtaPlacementArg = {
    name: string;
    cta: NewsletterCtaPlacement;
};

export const state = () => ({
    pages: {
        product: {} as Record<string, any>,
        solutions: {} as Record<string, any>,
        landing: {} as Record<string, any>
    },
    homePageCTAButtonMap: new Map<UserSegment,Types.IButton>(),
    featuredInsights: [],
    webinarPodcast: [],
    investmentsBestIdeasSection: {} as IBestIdeasSection,
    researchMethodologies: [] as Record<string, any>[],
    securityCta: {},
    footer: {
        columns: {} as IColumn,
        disclaimer: ''
    },
    salRecommended: {},
    headMeta: {},
    analystReports: {
        title: String,
        list: [],
        redirectUrl: String,
        isVisible: Boolean
    },
    stockExchangeEntries : {},
    stockExchangeHeadMetaEntries : {},
});

export const mutations: MutationTree<State> = {
    SET_PRODUCT_PAGE: (state, page: any) => {
        state.pages.product[page.slug] = page;
    },
    SET_SOLUTIONS_PAGE: (state, page: any) => {
        state.pages.solutions[page.slug] = page;
    },
    SET_LANDING_PAGE: (state, page: any) => {
        state.pages.landing[page.slug] = page;
    },
    SET_HOME_PAGE_CTA_BUTTON_MAP: (state, homePageCTAButtonMap: Map<UserSegment,Types.IButton>) => {
        state.homePageCTAButtonMap = homePageCTAButtonMap;
    },
    SET_FEATURED_INSIGHTS: (state, featuredInsights: any) => {
        state.featuredInsights = featuredInsights;
    },
    SET_WEBINAR_PODCAST: (state, webinarPodcast: any) => {
        state.webinarPodcast = webinarPodcast;
    },
    SET_BEST_IDEAS_SECTION: (state, investmentsBestIdeasSection: BestIdeasSection) => {
        state.investmentsBestIdeasSection = investmentsBestIdeasSection;
    },
    SET_RESEARCH_METHODOLOGIES: (state, researchMethodologies: any) => {
        state.researchMethodologies = researchMethodologies;
    },
    SET_SECURITY_CTA: (state, securityCta: any) => {
        state.securityCta = securityCta;
    },
    SET_FOOTER_COLUMNS: (state, footerPage: any) => {
        state.footer.columns = footerPage;
    },
    SET_FOOTER_DISCLAIMER: (state, disclaimer: string) => {
        state.footer.disclaimer = disclaimer;
    },
    SET_RECOMMENDED_TAGS: (state, recommended: any) => {
        state.salRecommended = recommended;
    },
    SET_HEAD_META: (state, headDetails: any) => {
        state.headMeta = headDetails;
    },
    SET_ANALYST_REPORTS_LIST: (state, list) => {
        state.analystReports.list = list;
    },
    SET_ANALYST_REPORTS_URL: (state, url) => {
        state.analystReports.redirectUrl = url;
    },
    SET_ANALYST_REPORTS_TITLE: (state, title) => {
        state.analystReports.title = title;
    },
    SET_ANALYST_REPORTS_VISIBILITY: (state, isVisible) => {
        state.analystReports.isVisible = isVisible;
    },
    SET_EXCHANGE_PAGE : (state, stockExchangeEntries) => {
        state.stockExchangeEntries = stockExchangeEntries;
    },
    SET_EXCHANGE_HEADMETA_PAGE_ENTRIES : (state, stockExchangeHeadMetaEntries) => {
        state.stockExchangeHeadMetaEntries = stockExchangeHeadMetaEntries;
    }
};

export const actions: ActionTree<RootState, State> = {

    async getLandingPage({ commit }, id) {

        const cs = new ContentStackService();
        const page = await cs.getLandingPage(id);

        commit('SET_LANDING_PAGE', page);
    },

    async getHomePageCTAButtonMap({ commit }) {

        const cs = new ContentStackService();
        const res = await cs.getHomePageCTAButtonMap();
        const buttonEntries = res.user_segment_button_map.map(({item}:any)=>[item.user_segment_name, item.button[0]]);
        const buttonMap = new Map(buttonEntries);

        commit('SET_HOME_PAGE_CTA_BUTTON_MAP', buttonMap);
    },

    async getFeaturedInsights({ commit }) {

        const cs = new ContentStackService();
        const res = await cs.getFeaturedInsights();
        const featuredInsights = res.modular_blocks.map((block: { item: { article_search: any; }; }) =>
            block.item.article_search
        );
        commit('SET_FEATURED_INSIGHTS', featuredInsights);
    },

    async getWebinarPodcast({ commit }) {

        const cs = new ContentStackService();
        const webinarPodcast = await cs.getWebinarPodcast();
        commit('SET_WEBINAR_PODCAST', webinarPodcast);
    },
    async getThankyouContent({ }, payload) {
        const cs = new ContentStackService();
        return await cs.getThankyouContent(payload);
    },
    async getThankyouPageContent({ },payload) {
        const cs = new ContentStackService();
        return await cs.getThankyouPageContent(payload);
    },
    async getResearchMethodologies({ commit }) {

        const cs = new ContentStackService();
        const res = await cs.getResearchMethodologies();

        const researchMethodologies = {
            title: RESEARCH_METHODOLOGIES_TITLE,
            methods: res[0].map(({
                headline,
                rich_text_editor
            }: IDisclaimerPropData) => ({ title: headline, description: rich_text_editor }))
        };

        commit('SET_RESEARCH_METHODOLOGIES', researchMethodologies);

        return researchMethodologies;
    },

    async getBestIdeasSection({ commit }) {
        const cs = new ContentStackService();
        const res = await cs.getBestIdeasSection();
        const investmentsBestIdeasSection = new BestIdeasSection(res.title, res.modular_blocks);

        commit('SET_BEST_IDEAS_SECTION', investmentsBestIdeasSection);

        return investmentsBestIdeasSection;
    },

    async getNewsletterSignupPage() {
        const cs = new ContentStackService();
        const res = await cs.getNewsletterSignupPage();

        return res;
    },

    async getSecurityCta({ commit }) {
        const cs = new ContentStackService();

        try {
            const res = await cs.getSecurityCta();

            commit('SET_SECURITY_CTA', res);
        }
        catch(e) {
            console.error('Error fetching CTA content: ', e);
        }
    },

    async getAnalystReportsList({ commit }) {
        const cs = new ContentStackService();

        try {
            const res = await cs.getAnalystReportsList();
            const reportsList = res.item_list.map((item: any) => item.doc_id_lookup );

            commit('SET_ANALYST_REPORTS_LIST', reportsList);
            commit('SET_ANALYST_REPORTS_URL', res.title_url);
            commit('SET_ANALYST_REPORTS_TITLE', res.title);
            commit('SET_ANALYST_REPORTS_VISIBILITY', res.isvisible);
        }
        catch(e) {
            console.error('Error fetching ANALYST REPORTS content: ', e);
        }
    },

    async getFooterPage({ commit }) {
        const cs = new ContentStackService();
        const res = await cs.getFooterPage();

        commit('SET_FOOTER_COLUMNS', res.sections.map((section: any)=>new FooterResult(section)));
        commit('SET_FOOTER_DISCLAIMER', res.disclaimer);
    },
    async getRecommendedTagsForSecurity({ commit }, payload) {
        const cs = new ContentStackService();
        const { exchange, ticker, secType } = payload;
        const res = await cs.getRecommendedTagsForSecurity(exchange,ticker,secType);

        commit('SET_RECOMMENDED_TAGS', res);
    },
    async getHeadMeta({ commit }, payload) {
        const cs = new ContentStackService();
        const res = await cs.getHeadMeta(payload.pageId);
        commit('SET_HEAD_META', res);
    },

    async getStockExchangeEntries({ commit },payload) {
        const cs = new ContentStackService();
        const res = await cs.getStockExchangeEntries(payload);
        commit('SET_EXCHANGE_PAGE',res);
    },

    async getStockExchangeHeadMetaEntries({ commit },payload) {
        const cs = new ContentStackService();
        const res = await cs.getStockExchangeHeadMetaEntries(payload);
        commit('SET_EXCHANGE_HEADMETA_PAGE_ENTRIES',res);
    }

};


export const getters: GetterTree<State, RootState> = {
    securityCta: (state) => {
        return state.securityCta;
    },
};