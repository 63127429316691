
import Vue from 'vue';
import McaCtaButton from '@mca-components-ui/contentstack-common/CtaButton.vue';
import McaCtaButtonMixin from '@mca-components-ui/contentstack-common/CtaButtonMixin.vue';
import * as Models from '@mca-components-ui/contentstack-common/models';
import { DEFAULT_USER_SEGMENT, UserSegment } from '@/configs/user';

export default Vue.extend({
    mixins: [McaCtaButtonMixin],
    components: {
        McaCtaButton
    },
    computed: {
        userSegment(): UserSegment {
            return this.$store.state.user.segment || DEFAULT_USER_SEGMENT;
        },
        button(): Models.CtaButton | undefined {

            let ctaButtonConfig;

            if(this.$store.state.contentstack.homePageCTAButtonMap.has(this.userSegment)) {
                ctaButtonConfig = this.$store.state.contentstack.homePageCTAButtonMap.get(this.userSegment);
            }
            else if(this.$store.state.contentstack.homePageCTAButtonMap.has(DEFAULT_USER_SEGMENT)) {
                ctaButtonConfig = this.$store.state.contentstack.homePageCTAButtonMap.get(DEFAULT_USER_SEGMENT);
            }
            else {
                console.error('No CTA button found for user segment: ' + this.userSegment);
                return;
            }

            return new Models.CtaButton(ctaButtonConfig);
        }
    },
    async fetch () {
        await this.$store.dispatch('contentstack/getHomePageCTAButtonMap');
    }
});
