import { ActionTree, MutationTree } from "vuex";
import MarketReportService from "~/services/markets/marketReportService";
import AsxMarketReportData from "~/types/markets/asxMarketReport";
import type { State as RootState } from "~/store";

export const state = () => ({
    asxMarketData: {
        content: [''] as Array<string>,
        id: "",
        publishedDate: "",
        title: "",
    } as AsxMarketReportData,
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {
    SET_MARKET_REPORT: (state: State, asxMarketData: AsxMarketReportData) => {
        state.asxMarketData = asxMarketData;
    },
};

export const actions: ActionTree<RootState, State> = {
    async fetchLatest({ commit }) {
        const asxMarket = new MarketReportService(this.app.apolloProvider!.defaultClient);
        const { data } = await asxMarket.getASXMarketReportData();
        commit("SET_MARKET_REPORT", data.getAsxMarketReport);
    },
};

