import { ActionTree, MutationTree } from 'vuex';
import type {State as RootState} from '~/store';
import { getStack } from '~/services/content-stack/stack';
import * as ContentStack from '../configs/contentstack';

interface  ContactData {
    title: string;
    body_text: string;
}
interface  TermsData extends ContactData{};
interface AdvertiseData extends ContactData{}  ;

export const state = () => ({
    contactData: {} as ContactData,
    advertiseData: {} as AdvertiseData,
    termsData: {} as TermsData
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {
    CONTACT_US: (currentState, contactData: ContactData) => {
        currentState.contactData = contactData;
    },
    ADVERTISE_WITH_US: (currentState, advertiseData: AdvertiseData) => {
        currentState.advertiseData = advertiseData;
    },
    TERMS_AND_CONDITIONS :(currentState, termsData: TermsData)=>{
        currentState.termsData = termsData;
    }
};

export const actions: ActionTree<RootState, State> = {
    async getContactUs({ commit }) {
        const response = await getStack()
            .ContentType(ContentStack.ABOUT_US.ContentType)
            .Entry(ContentStack.ABOUT_US.Contact_Us_Entry_Id).fetch();
        commit('CONTACT_US', response.toJSON());
    },
    async getAdvertiseWithUs({ commit }) {
        const response = await getStack()
            .ContentType(ContentStack.ABOUT_US.ContentType)
            .Entry(ContentStack.ABOUT_US.Advertise_With_Us_Entry_Id).fetch();
        commit('ADVERTISE_WITH_US', response.toJSON());
    },
    async getTermsCondition({ commit }) {
        const response = await getStack()
            .ContentType(ContentStack.ABOUT_US.ContentType)
            .Entry(ContentStack.ABOUT_US.Terms_Condition_Entry_Id).fetch();
        commit('TERMS_AND_CONDITIONS', response.toJSON());
    }
};