import type { Types as ApiTypes } from "@mca-dfd-api/graphql-schema";
import { ApolloClient } from 'apollo-client';

import AsxMarketReportQuery from "~/services/queries/getAsxMarketReport.gql";
import globalMarketReportQuery from "~/services/queries/getGlobalMarketReport.gql";
import priceFairValueQuery from "~/services/queries/getPriceFairValue.gql";
import getGlobalMarketData from "~/services/queries/getGlobalMarketData.gql";


type AsxMarketReportResponse = {
    getAsxMarketReport: ApiTypes.AsxMarketReport;
};

type GlobalMarketReportResponse = {
    getGlobalMarketReport: ApiTypes.GlobalMarketData;
};

type PriceFairValueResponse = {
    getPriceFairValue: ApiTypes.PriceToFairValue;
};

type GlobalMarketDataResponse = {
    globalMarketData: ApiTypes.MarketData;
};

class MarketReportService {
    constructor(public gqlClient: ApolloClient<any>) { }

    async getASXMarketReportData() {
        return this.gqlClient.query<AsxMarketReportResponse>({
            query: AsxMarketReportQuery
        });
    }
    async getGlobalMarketReportData() {
        return this.gqlClient.query<GlobalMarketReportResponse>({
            query: globalMarketReportQuery
        });
    }
    async getPriceToFairValueData() {
        return this.gqlClient.query<PriceFairValueResponse>({
            query: priceFairValueQuery
        });
    }
    async getGlobalMarketData() {
        return this.gqlClient.query<GlobalMarketDataResponse>({
            query: getGlobalMarketData
        });
    }
}
export default MarketReportService;
