
import Vue from 'vue';
import McaAdvertContainer, {ILoadEventParams} from '@mca-components-ui/advert-container';
import type { State as AdvertsState } from '@/store/adverts';

export default Vue.extend({
    data() {
        return {
            isLoaded: false,
            isScrolled: false,
            height: 250
        };
    },
    mounted() {
        if(this.isViewable) {
            this.renderAdvert();
        }
    },
    watch: {
        '$route': function() {
            this.$store.commit('adverts/LAST_SAVED_VIEW', new Date());
        }
    },
    components: {
        McaAdvertContainer
    },
    computed: {
        hasAdvert(): boolean {
            return (this.$store.state.adverts as AdvertsState).placements.has('PUSHDOWN_SPLASH');
        },
        advertHTML(): string | undefined {
            return (this.$store.state.adverts as AdvertsState).placements.get('PUSHDOWN_SPLASH');
        },
        isViewable(): boolean {
            return this.$store.getters['adverts/isPulldownSplashViewable'];
        },
        isMobileAdvert(): boolean {
            return this.$store.getters['layout/isMobileViewport'];
        },
        isTabletAdvert(): boolean {
            return this.$store.getters['layout/isTabletViewport'];
        },
        isDesktopAdvert(): boolean {
            return this.$store.getters['layout/isDesktopViewport'];
        },
        size(): string {
            if(this.height >= 500) {
                return 'large';
            }
            else {
                return 'medium';
            }
        }
    },
    methods: {
        renderAdvert() {
            localStorage.setItem('advert.splash', JSON.stringify({lastViewed: (new Date()).toISOString()}));
        },
        onLoad(advert: ILoadEventParams) {
            this.height = advert.height;

            const observer = new IntersectionObserver((entries) => {
                if(!entries[0].isIntersecting) {
                    this.$emit('scrolled');
                    this.isScrolled = true;
                }
            });

            observer.observe(this.$el);
        }
    }
});
