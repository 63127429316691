export const DataError = () => import('../../components/data-error.vue' /* webpackChunkName: "components/data-error" */).then(c => wrapFunctional(c.default || c))
export const AdvertsFixedPullDown = () => import('../../components/adverts/FixedPullDown.vue' /* webpackChunkName: "components/adverts-fixed-pull-down" */).then(c => wrapFunctional(c.default || c))
export const AuthRegistrationForm = () => import('../../components/auth/RegistrationForm.vue' /* webpackChunkName: "components/auth-registration-form" */).then(c => wrapFunctional(c.default || c))
export const AuthSetPasswordForm = () => import('../../components/auth/SetPasswordForm.vue' /* webpackChunkName: "components/auth-set-password-form" */).then(c => wrapFunctional(c.default || c))
export const ChartMwcMarkets = () => import('../../components/chart/MwcMarkets.vue' /* webpackChunkName: "components/chart-mwc-markets" */).then(c => wrapFunctional(c.default || c))
export const ChartIntradayMovement = () => import('../../components/chart/intraday-movement.vue' /* webpackChunkName: "components/chart-intraday-movement" */).then(c => wrapFunctional(c.default || c))
export const ChartOverviewIntraday = () => import('../../components/chart/overview-intraday.vue' /* webpackChunkName: "components/chart-overview-intraday" */).then(c => wrapFunctional(c.default || c))
export const ChartOverview = () => import('../../components/chart/overview.vue' /* webpackChunkName: "components/chart-overview" */).then(c => wrapFunctional(c.default || c))
export const ChartValuation = () => import('../../components/chart/valuation.vue' /* webpackChunkName: "components/chart-valuation" */).then(c => wrapFunctional(c.default || c))
export const CommonUserTypeSelection = () => import('../../components/common/UserTypeSelection.vue' /* webpackChunkName: "components/common-user-type-selection" */).then(c => wrapFunctional(c.default || c))
export const EloquaForm = () => import('../../components/eloqua/form.vue' /* webpackChunkName: "components/eloqua-form" */).then(c => wrapFunctional(c.default || c))
export const ErrorsPageError = () => import('../../components/errors/page-error.vue' /* webpackChunkName: "components/errors-page-error" */).then(c => wrapFunctional(c.default || c))
export const HomepageRightColumn = () => import('../../components/homepage/RightColumn.vue' /* webpackChunkName: "components/homepage-right-column" */).then(c => wrapFunctional(c.default || c))
export const IdeasBestIdeasCards = () => import('../../components/ideas/bestIdeasCards.vue' /* webpackChunkName: "components/ideas-best-ideas-cards" */).then(c => wrapFunctional(c.default || c))
export const IdeasCard = () => import('../../components/ideas/card.vue' /* webpackChunkName: "components/ideas-card" */).then(c => wrapFunctional(c.default || c))
export const IdeasCardScreener = () => import('../../components/ideas/cardScreener.vue' /* webpackChunkName: "components/ideas-card-screener" */).then(c => wrapFunctional(c.default || c))
export const IdeasFilter = () => import('../../components/ideas/filter.vue' /* webpackChunkName: "components/ideas-filter" */).then(c => wrapFunctional(c.default || c))
export const IdeasResearchMethodologies = () => import('../../components/ideas/researchMethodologies.vue' /* webpackChunkName: "components/ideas-research-methodologies" */).then(c => wrapFunctional(c.default || c))
export const IdeasScreenerTable = () => import('../../components/ideas/screenerTable.vue' /* webpackChunkName: "components/ideas-screener-table" */).then(c => wrapFunctional(c.default || c))
export const IdeasWebinarPodcastCardContent = () => import('../../components/ideas/webinarPodcastCardContent.vue' /* webpackChunkName: "components/ideas-webinar-podcast-card-content" */).then(c => wrapFunctional(c.default || c))
export const InsightsArticleList = () => import('../../components/insights/article-list.vue' /* webpackChunkName: "components/insights-article-list" */).then(c => wrapFunctional(c.default || c))
export const InvestmentsNewsAndAnalysis = () => import('../../components/investments/NewsAndAnalysis.vue' /* webpackChunkName: "components/investments-news-and-analysis" */).then(c => wrapFunctional(c.default || c))
export const InvestmentsSecurityHeaderCta = () => import('../../components/investments/SecurityHeaderCta.vue' /* webpackChunkName: "components/investments-security-header-cta" */).then(c => wrapFunctional(c.default || c))
export const InvestmentsStockExchangeTable = () => import('../../components/investments/StockExchangeTable.vue' /* webpackChunkName: "components/investments-stock-exchange-table" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavList = () => import('../../components/layout/_nav-list.vue' /* webpackChunkName: "components/layout-nav-list" */).then(c => wrapFunctional(c.default || c))
export const LayoutAboutUs = () => import('../../components/layout/about-us.vue' /* webpackChunkName: "components/layout-about-us" */).then(c => wrapFunctional(c.default || c))
export const LayoutBreadcrumb = () => import('../../components/layout/breadcrumb.vue' /* webpackChunkName: "components/layout-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const LayoutCtaButton = () => import('../../components/layout/cta-button.vue' /* webpackChunkName: "components/layout-cta-button" */).then(c => wrapFunctional(c.default || c))
export const LayoutMainHeader = () => import('../../components/layout/main-header.vue' /* webpackChunkName: "components/layout-main-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutMainNavigation = () => import('../../components/layout/main-navigation.vue' /* webpackChunkName: "components/layout-main-navigation" */).then(c => wrapFunctional(c.default || c))
export const LayoutMobileNavigation = () => import('../../components/layout/mobile-navigation.vue' /* webpackChunkName: "components/layout-mobile-navigation" */).then(c => wrapFunctional(c.default || c))
export const LayoutSecuritiesResult = () => import('../../components/layout/securities-result.vue' /* webpackChunkName: "components/layout-securities-result" */).then(c => wrapFunctional(c.default || c))
export const LayoutSecurityList = () => import('../../components/layout/security-list.vue' /* webpackChunkName: "components/layout-security-list" */).then(c => wrapFunctional(c.default || c))
export const LearnArticles = () => import('../../components/learn/articles.vue' /* webpackChunkName: "components/learn-articles" */).then(c => wrapFunctional(c.default || c))
export const LineupAnalystReports = () => import('../../components/lineup/AnalystReports.vue' /* webpackChunkName: "components/lineup-analyst-reports" */).then(c => wrapFunctional(c.default || c))
export const LineupExplore = () => import('../../components/lineup/Explore.vue' /* webpackChunkName: "components/lineup-explore" */).then(c => wrapFunctional(c.default || c))
export const LineupLearnToInvest = () => import('../../components/lineup/LearnToInvest.vue' /* webpackChunkName: "components/lineup-learn-to-invest" */).then(c => wrapFunctional(c.default || c))
export const LineupMarketReports = () => import('../../components/lineup/MarketReports.vue' /* webpackChunkName: "components/lineup-market-reports" */).then(c => wrapFunctional(c.default || c))
export const LineupPodcasts = () => import('../../components/lineup/Podcasts.vue' /* webpackChunkName: "components/lineup-podcasts" */).then(c => wrapFunctional(c.default || c))
export const LineupSpecialTopics = () => import('../../components/lineup/SpecialTopics.vue' /* webpackChunkName: "components/lineup-special-topics" */).then(c => wrapFunctional(c.default || c))
export const LineupSpecialists = () => import('../../components/lineup/Specialists.vue' /* webpackChunkName: "components/lineup-specialists" */).then(c => wrapFunctional(c.default || c))
export const LineupStandardCollections = () => import('../../components/lineup/StandardCollections.vue' /* webpackChunkName: "components/lineup-standard-collections" */).then(c => wrapFunctional(c.default || c))
export const LineupTopStories = () => import('../../components/lineup/TopStories.vue' /* webpackChunkName: "components/lineup-top-stories" */).then(c => wrapFunctional(c.default || c))
export const LineupWebinars = () => import('../../components/lineup/Webinars.vue' /* webpackChunkName: "components/lineup-webinars" */).then(c => wrapFunctional(c.default || c))
export const MarketAsxIntradayChart = () => import('../../components/market/asx-intraday-chart.vue' /* webpackChunkName: "components/market-asx-intraday-chart" */).then(c => wrapFunctional(c.default || c))
export const MarketGlobal = () => import('../../components/market/global.vue' /* webpackChunkName: "components/market-global" */).then(c => wrapFunctional(c.default || c))
export const MarketIndices = () => import('../../components/market/indices.vue' /* webpackChunkName: "components/market-indices" */).then(c => wrapFunctional(c.default || c))
export const MarketIntradayCompare = () => import('../../components/market/intraday-compare.vue' /* webpackChunkName: "components/market-intraday-compare" */).then(c => wrapFunctional(c.default || c))
export const MarketIntradayMovement = () => import('../../components/market/intraday-movement.vue' /* webpackChunkName: "components/market-intraday-movement" */).then(c => wrapFunctional(c.default || c))
export const MarketMoversAsx = () => import('../../components/market/movers-asx.vue' /* webpackChunkName: "components/market-movers-asx" */).then(c => wrapFunctional(c.default || c))
export const MarketMovers = () => import('../../components/market/movers.vue' /* webpackChunkName: "components/market-movers" */).then(c => wrapFunctional(c.default || c))
export const MarketOverviewChart = () => import('../../components/market/overview-chart.vue' /* webpackChunkName: "components/market-overview-chart" */).then(c => wrapFunctional(c.default || c))
export const MarketRegionPriceFairValue = () => import('../../components/market/region-price-fair-value.vue' /* webpackChunkName: "components/market-region-price-fair-value" */).then(c => wrapFunctional(c.default || c))
export const MarketValuationChart = () => import('../../components/market/valuation-chart.vue' /* webpackChunkName: "components/market-valuation-chart" */).then(c => wrapFunctional(c.default || c))
export const ReportInvestmentIdeas = () => import('../../components/report/investment-ideas.vue' /* webpackChunkName: "components/report-investment-ideas" */).then(c => wrapFunctional(c.default || c))
export const ReportRecommendedTags = () => import('../../components/report/recommended-tags.vue' /* webpackChunkName: "components/report-recommended-tags" */).then(c => wrapFunctional(c.default || c))
export const ReportSalUpdated = () => import('../../components/report/sal-updated.vue' /* webpackChunkName: "components/report-sal-updated" */).then(c => wrapFunctional(c.default || c))
export const SearchNews = () => import('../../components/search/news.vue' /* webpackChunkName: "components/search-news" */).then(c => wrapFunctional(c.default || c))
export const SearchSecurities = () => import('../../components/search/securities.vue' /* webpackChunkName: "components/search-securities" */).then(c => wrapFunctional(c.default || c))
export const MarketSectorPerformance = () => import('../../components/market/sector/performance.vue' /* webpackChunkName: "components/market-sector-performance" */).then(c => wrapFunctional(c.default || c))
export const MarketSectorSection = () => import('../../components/market/sector/section.vue' /* webpackChunkName: "components/market-sector-section" */).then(c => wrapFunctional(c.default || c))
export const MarketSectorValuation = () => import('../../components/market/sector/valuation.vue' /* webpackChunkName: "components/market-sector-valuation" */).then(c => wrapFunctional(c.default || c))
export const ModalMethodologyStock = () => import('../../components/modal/methodology/stock.vue' /* webpackChunkName: "components/modal-methodology-stock" */).then(c => wrapFunctional(c.default || c))
export const MarketingContentstackBlocks = () => import('../../components/marketing/contentstack/blocks.vue' /* webpackChunkName: "components/marketing-contentstack-blocks" */).then(c => wrapFunctional(c.default || c))
export const MarketingNewslettersCta = () => import('../../components/marketing/newsletters/cta.vue' /* webpackChunkName: "components/marketing-newsletters-cta" */).then(c => wrapFunctional(c.default || c))
export const ReportInvestmentStrategy = () => import('../../components/report/investment/strategy.vue' /* webpackChunkName: "components/report-investment-strategy" */).then(c => wrapFunctional(c.default || c))
export const ReportStockProfile = () => import('../../components/report/stock/profile.vue' /* webpackChunkName: "components/report-stock-profile" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
