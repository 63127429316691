export const IwtUniverseIds = new Map<string, string[]>([
    ["ST", ["E0WWE$$ALL"]],
    ['FE', ["ETEXG$XASX", "ETEXG$XNZE", "ETEXG$CHIA"]],
    ['FO', ["FOAUS$$ALL", "FONZL$$ALL", "FOAUS$$BAS", "FONZL$$IBF", "FONZL$$SAF"]],
    ["FC", ["CEEXG$XASX"]],
    ['XI', ["IXALL$$ALL"]]
]);

export const GetIwtUniverseIds = (securityTypes: string[]): string[] => {
    const toReturn: string[] = [];
    for (const secType of securityTypes) {
        const universes = IwtUniverseIds.get(secType) || [];
        toReturn.push(...universes);
    }
    return toReturn;
};

export const DateFormats = {
    shortDate: "DD MMM YYYY",
    dayDateTime: "dddd, DD MMM YYYY hh:mm a",
    shortDateWithSeparator: 'DD/MM/YYYY'
};

export const Gtm = {
    eventName: "mca-event"
};

export const SECURITY_TYPE_MAP = new Map([
    ["ST", "Stocks"],
    ["FO", "Funds"],
    ["FE", "ETFs"],
    ["FC", "LICs"],
    ["F0", "Bonds"],
    ["XI", "Markets"],
    ["IX", "Markets"]
]);

export const SAL_PLACEMENT_MAP = new Map([
    ["ST", "sal_stocks"],
    ["FO", "sal_funds"],
    ["FE", "sal_etfs"],
    ["FC", "sal_lics"],
    ["IX", "sal_indexes"],
]);