import type { NuxtAxiosInstance } from '@nuxtjs/axios';

export class SalService implements ISalService {
    async getSalToken(axios: NuxtAxiosInstance): Promise<ITokenResponse> {
        try {            
            const url = '/token/sal';
            const { data } = await axios.post(url);
            const authToken = {
                auth : { issuedAt: data.auth.issuedAt,
                    accessToken: data.auth.accessToken,
                    expiresIn: data.auth.expiresIn,
                    tokenType: data.auth.tokenType},
                realtimeKey: data.realtimeKey
            };
            
            return authToken;
        }
        catch (err) {
            console.error('error occured in get SAL Token service...', err);
            throw err;
        }
    }
    
    isTokenExpired(authToken: IAuthResponse): boolean {
        if (authToken) {
            const tokenexpiration: Date = new Date();
            const tokenExpireInTime = new Date(parseInt(authToken.issuedAt));
            tokenExpireInTime.setSeconds(authToken.expiresIn);                
            return tokenExpireInTime <= tokenexpiration;
        }
        else { 
            return true;
        }
    } 
}

export default SalService;


export interface ISalService {
    getSalToken(axios: NuxtAxiosInstance): Promise<ITokenResponse>;   
    isTokenExpired(authToken: IAuthResponse): boolean; 
}

export interface ITokenResponse {
    auth: IAuthResponse,
    realtimeKey: string;   
}

export interface IAuthResponse {
    issuedAt: string,
    accessToken: string,
    expiresIn: number,
    tokenType: string
}


