import { Context } from '@nuxt/types';
import { debounce } from "debounce";
import { Gtm } from '@/configs/common';

const DEPTHS = [25, 50, 75, 90];

export default function({ app }: Context) {

    let lastScrollDepthIndexRecorded = -1;

    app.router!.afterEach(async () => {
        // Reset the scroll depth index on each page change
        setTimeout(() => {
            lastScrollDepthIndexRecorded = -1;
        }, 2000);
    });

    const trackScrollDepth = () => {

        // If we have recorded all scroll depths, then stop tracking
        if(lastScrollDepthIndexRecorded >= (DEPTHS.length - 1)) {
            return;
        }
        
        // Get the current vertical position of the scroll bar
        const scrollPosition = window.pageYOffset;

        // Get the height of the entire page
        const pageHeight = document.body.scrollHeight;

        const windowHeight = window.innerHeight;

        const highestIndex = getHighestScrollDepthIndex(scrollPosition, pageHeight, windowHeight);

        if(highestIndex > lastScrollDepthIndexRecorded) {

            app.$gtm.push({
                event: Gtm.eventName,
                category: 'scroll',
                action: 'percent_scrolled',
                label: `${DEPTHS[highestIndex]}%`,
                value: DEPTHS[highestIndex],
            });

            lastScrollDepthIndexRecorded = highestIndex;
        }

    };

    // Debouncing function to limit the number of events being sent
    const debouncedTrackScrollDepth = debounce(trackScrollDepth, 100);

    // Listen for the scroll event and call the debouncedTrackScrollDepth function
    window.addEventListener('scroll', debouncedTrackScrollDepth);
}

/**
 * Gets the index of the highest scroll depth that has not yet been recorded
 * @param scrollPosition the current vertical position of the scroll bar
 * @param pageHeight the height of the entire page
 */
function getHighestScrollDepthIndex(scrollPosition: number, pageHeight: number, windowHeight: number) {

    // Calculate the percentage of the page that has been scrolled
    const scrollPercentage = (scrollPosition / (pageHeight - windowHeight)) * 100;

    // Find the highest depth that has not yet been recorded (can skip scroll depths of scrolls that are too fast)
    const highestDepth      = DEPTHS.slice().reverse().find((depth) => scrollPercentage >= depth);
    const highestDepthIndex = DEPTHS.findIndex((depth) => depth === highestDepth);

    return highestDepthIndex;
}