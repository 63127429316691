import { Context, Plugin } from '@nuxt/types';
import { Marketing } from '@mca-components-ui/models';

declare module '@nuxt/types' {
    interface Context {
        $getCampaign(): Marketing.Campaigns.Campaign | undefined
    }
}

const marketingCampaignPlugin: Plugin = (ctx: Context) => {
    const campaignCookieName = "au.marketing-campaign";

    ctx.$getCampaign = (): Marketing.Campaigns.Campaign | undefined => {
        return getSaved();
    };

    const initCampaign = () => {
        
        const campaignParams = getCampaignParams();
        const isSameSite = isSameReferrerSite();

        if(campaignParams == undefined && isSameSite) {
            return;
        }
        const newCampaign = new Marketing.Campaigns.Campaign(campaignParams || {});
        let updateNewParams = false;
        if(!newCampaign.isValid()) {
            if(isSameSite && campaignParams) {
                updateNewParams = true;
            }
            else {
                return;
            }
        }

        const savedCampaign = getSaved();
        
        if(savedCampaign) {
            if(updateNewParams && campaignParams) {
                savedCampaign.updateParams(campaignParams);
            } 
            else if(!savedCampaign.isEqual(newCampaign)) {
                savedCampaign.update(newCampaign);
            }
            save(savedCampaign);
        }
        else {
            save(newCampaign);
        }
    };

    const isSameReferrerSite = (): boolean =>{
        if(!Boolean(document.referrer)) {
            return false;
        }
        return new URL(document.referrer).hostname == location.hostname;
    };

    const getCampaignParams = () : Marketing.Campaigns.CampaignData | undefined => {
        if(!Boolean(location.search)) {
            return;
        }
        const query = new URLSearchParams(location.search);
        const retObj: Marketing.Campaigns.CampaignData = {};

        for(const param of Marketing.Campaigns.CAMPAIGN_PARAMS) {
            const paramValue = query.get(param);
            if(paramValue != undefined) {
                retObj[param] = paramValue;
            }
        }
        if(Object.keys(retObj).length > 0) {
            return retObj;
        }
    };

    const save = (campaign: Marketing.Campaigns.Campaign): void => {
        ctx.app.$cookies.set(campaignCookieName, campaign, { domain: ctx.$config.sharedCookieDomain, path: "/" });
    };

    const getSaved = (): Marketing.Campaigns.Campaign | undefined =>{

        const campaignCookie = ctx.app.$cookies.get(campaignCookieName);
        if(campaignCookie) {
            const campaign = new Marketing.Campaigns.Campaign(campaignCookie as Marketing.Campaigns.CampaignData);

            if(campaign.isValid()) {
                return campaign;
            }
        }
    };
    initCampaign();
};

export default marketingCampaignPlugin;