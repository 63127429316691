import { ActionTree, MutationTree } from 'vuex';
import type { State as RootState } from '~/store';
import { getStack } from '~/services/content-stack/stack';
import * as ContentStack from '../configs/contentstack';
import { MCA } from '@mca-components-ui/types';

export const state = () => ({
    PageDocuments: [] as MCA.Models.Contentstack.ITopStories[],
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {
    LEARN_GUIDES: (state: State, contentData: MCA.Models.Contentstack.ITopStories[]) => {
        state.PageDocuments = contentData;
    },
};

export const actions: ActionTree<RootState, State> = {
    async fetchLearnContentStack({ commit }, payload: string) {
        if (payload) {
            const response = await getStack()
                .ContentType(ContentStack.LEARN_INVEST.ContentType)
                .Entry(payload).fetch();
            const learnPageDocs = response?.toJSON().docs;
            commit('LEARN_GUIDES', learnPageDocs);
        }
    },
};