
import Vue from 'vue';
import { MdsListGroup } from '@mds/list-group';
import { MdsTabs, MdsTabsItem } from '@mds/tabs';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import * as navigation from '@/configs/nav';
import NavList from './_nav-list.vue';
import type { Route } from 'vue-router';

export default Vue.extend({
    props: {
        currentRoute: {
            type: Object as () => Route,
            required: true
        },
    },
    mounted() {
        this.emitInterface();
        window.onscroll=()=>{
            this.menu = '';
        };
    },
    data() {
        return {
            menu: '',
            navigation
        };
    },
    computed: {
        isOpen(): boolean {
            return this.menu !== '';
        }
    },
    watch: {
        $route() {
            this.menu = '';
        },
    },
    components: {
        MdsTabs, MdsTabsItem,
        MdsListGroup,
        MdsLayoutGrid, MdsRow, MdsCol,
        NavList
    },
    methods: {
        open(menu: string) {
            if(this.menu === menu) {
                this.close();
            }
            else if(this.isOpen) {
                this.menu = '';
                setTimeout(() => {
                    this.menu = menu;
                }, 200);
            }
            else {
                this.menu = menu;
            }
        },
        close() {
            this.menu = '';
        },
        emitInterface() {
            this.$emit("interface", {
                close: () => this.close()
            } as IMainNav);
        }
    }
});
export interface IMainNav {
close: () => void;
}
