import { ActionTree, MutationTree } from "vuex";

import { Content } from "@mca-components-ui/models";
import DocumentRequest from "~/types/document/documentRequest";
import DocumentService from "~/services/document/documentService";
import type { IRelatedSecurity } from "@mca-components-ui/mentioned-securities";
import type { ISecurity } from "@mca-components-ui/related-securities";
import NewsRequest from "~/types/document/newsRequest";
import { State as RootState } from "~/store";
import * as ContentStackCommon from '@mca-components-ui/contentstack-common';
import { PLACEHOLDERS } from "~/configs/images";
import ArticleSitemapRequest from "~/types/document/articleSitemapRequest";

export const state = () => ({
    article: {} as Content.Document,
    newsArticles: [] as Content.Document[],
    relatedSecurities: [] as Array<IRelatedSecurity & ISecurity>,
    disclaimerBody: {} as ContentStackCommon.Models.Disclaimer
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {
    SET_RELATED_SECURITIES: (state: State, payload: any[]) => {
        state.relatedSecurities = payload.map((sec: any) =>
            ({
                ...sec,
                Id: sec.id,
                SecId: sec.secId,
                SalId: sec.securityType === 'ST' ? sec.performanceId : sec.fundShareClassId,
                Name: sec.name,
                InvestmentTypeId: sec.investmentType,
                InvestmentType: sec.investmentType,
                ExchangeCode: sec.exchangeCode,
                Ticker: sec.ticker,
                APIR: sec.apir,
                SecurityType: sec.securityType,
                EquityResearchStarRating: sec.equityResearchStarRating,
                QuantitativeStarRating: sec.quantitativeStarRating,
                FlagshipFundAnalystRatingScale: sec.flagshipFundAnalystRatingScale,
                AnalystRatingScale: sec.analystRatingScale,
                "Intraday.Price": sec.intradayPrice?.price,
                "Intraday.NetChange": sec.intradayPrice?.netChange,
                "Intraday.NetChangePercent": sec.intradayPrice?.netChangePercent,
                ShareClassId: sec.id,
                FundShareClassId: sec.fundShareClassId,
                PerformanceId: sec.performanceId,
                Symbol: sec.symbol ? sec.symbol : sec.ticker,
                symbol: sec.symbol ? sec.symbol : sec.ticker,
                HoldingTypeId: sec.holdingTypeId,
                Currency: sec.currency,
                ClosePrice: sec.closePrice,
                ExchangeId: sec.exchangeId
            })
        );
    },
    SET_DOCUMENT_DATA: (state: State, payload: Content.Document) => {
        state.article = payload;
    },
    SET_NEWS_DATA: (state: State, news: Content.Document[]) => {
        state.newsArticles = news;
    },
    SET_DISCLAIMER: (state: State, disclaimerBody: ContentStackCommon.Models.Disclaimer) => {
        state.disclaimerBody = disclaimerBody;
    }
};

export const actions: ActionTree<RootState, State> = {
    async fetchDocument({ commit }, payload: DocumentRequest) {
        const documentService = new DocumentService(this.app.apolloProvider!.defaultClient);
        const document = await documentService.getDocument(payload);

        if(!document.data.getDocument) {
            throw new Error('Document not found');
        }
        
        commit("SET_RELATED_SECURITIES", document?.data.getDocument.relatedSecurities || []);
        const documentData = new Content.Document(document?.data.getDocument);
        if(documentData && documentData.primaryAuthor && !documentData.primaryAuthor.imageUrl) {
            documentData.primaryAuthor.imageUrl = PLACEHOLDERS.avatar;
        }
        commit("SET_DOCUMENT_DATA", documentData);
        const documentBody = new ContentStackCommon.Models.Disclaimer({
            "title": "",
            "headline": "",
            "rich_text_editor": documentData.disclaimerBody,
            "background": {
                "color": {
                    "default_color": "#FFFFFF",
                    "is_gradient": false,
                    "gradient_angle": 180,
                    "color_from": "#000000",
                    "color_stop": "#000000"
                },
                "image": {
                    "uid": "",
                    "tags": [],
                    "content_type": "",
                    "file_size": "",
                    "filename": "",
                    "url": "",
                    "parent_uid": "",
                    "title": "",
                    "description": "",
                    "updated_at": ""
                },
                "dark": false
            }
        });
        commit("SET_DISCLAIMER",documentBody);
    },

    async fetchNews({ commit }, params: NewsRequest) {

        const documentService = new DocumentService(this.app.apolloProvider!.defaultClient);
        const response = await documentService.getNews(params);

        const newsArticles = response.data.getNews.documents;
        
        commit("SET_NEWS_DATA", newsArticles);
    },

    async fetchArticleSitemapsByMonth({}, params: ArticleSitemapRequest) {
        const documentService = new DocumentService(this.app.apolloProvider!.defaultClient);
        const response = await documentService.getArticleSitemapData(params);
        return response.data.getArticleSitemapDataByMonth.documents;
    },
};

