import * as contentstack from "contentstack";

export function getStack() {
    const stack = contentstack.Stack({
        api_key: process.env.CS_API_KEY as string,
        delivery_token: process.env.CS_DELIVERY_TOKEN as string,
        environment: process.env.CS_ENVIRONMENT as 'local' | 'dev' | 'qa' | 'prod'
    });
    return stack;
}
