import {ActionTree} from "vuex";
import { State as RootState } from "~/store";
import {TopicCollectionResponse, ITopicPayload} from "~/types/document/topic";
import TopicService from "~/services/topic/topicService";

export const state = ()=>({
    topic: {
    } as TopicCollectionResponse
});

export type State= ReturnType<typeof state>;

export const actions: ActionTree<RootState,State>= {
    async fetchTopics({}, payload:ITopicPayload) {
        const service= new TopicService(this.app.apolloProvider!.defaultClient);
        const topicResponse= await service.getTopics(payload);

        return topicResponse.data;
    }
};