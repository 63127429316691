import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import jwtDecode from 'jwt-decode';

export class MaasService implements IMaasService {

    async getToken(axios: NuxtAxiosInstance): Promise<TokenResponse> {
        try {
            const url = '/token/maas';
            const { data } = await axios.post(url);

            return {
                access_token: data.access_token,
                expires_in: data.expires_in,
                token_type: data.token_type
            };
        }
        catch (err) {
            console.error('error occured in getToken service...', err);
            throw err;
        }
    }

    decodeMaasToken(maasToken: string): MaasToken {
        return jwtDecode(maasToken);
    }

    isTokenExpired(jwt: MaasToken): boolean {
        return jwt.exp < (Date.now() / 1000);
    }
}

export default MaasService;


export interface IMaasService {
    getToken(axios: NuxtAxiosInstance): Promise<TokenResponse>;
    decodeMaasToken(maasToken: string): MaasToken;
    isTokenExpired(jwt: MaasToken): boolean;
}

export interface TokenResponse {
    access_token: string;
    expires_in: number;
    token_type: string;
    err?: unknown
}

export interface MaasToken {

    /**
     * (Expiration Time) Claim. Seconds since EPOC
     * https://tools.ietf.org/html/rfc7519#section-4.1.4
     */
    exp: number;

    /**
     * (Issued At) Claim
     * The "iat" (issued at) claim identifies the time at which the JWT was issued.
     */
    iat?: number;

    /**
     * "iss" (Issuer) Claim
     *  The "iss" (issuer) claim identifies the principal that issued the JWT.
     */
    iss?: string;

    /**
     * "jti" (JWT ID) Claim
     *  The "jti" (JWT ID) claim provides a unique identifier for the JWT.
     */
    jti?: string;

    /**
     * "sub" (Subject) Claim
     *  The "sub" (subject) claim identifies the principal that is the subject of the JWT
     * User Global login ID, can be used for premium EC components interaction
     */
    sub: string;

    token_use: string;
    auth_time: number;
    client_id: string;
    username: string;
}