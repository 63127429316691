
import Vue from 'vue';
import { MdsListGroupItem } from '@mds/list-group';
import MdsIcon from '@mds/icon';

export default Vue.extend({
    props: {
        items: Array
    },
    data() {
        return {};
    },
    components: {
        MdsIcon,
        MdsListGroupItem,
    }
});
