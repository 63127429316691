export const PLACEHOLDERS = {
    avatar: 'https://cdn.morningstar.com.au/mca/s/images/mstar-default-logo-1x1.svg',
    card: 'https://cdn.morningstar.com.au/mca/s/images/mstar-default-image-16x9.svg'
};
export const IPX_CONFIG = {
    domains: (process.env.IPX_ALLOWED_DOMAINS || '').split(','),
    presets: {
        avatarSm: {
            modifiers: {
                format: 'webp',
                width: 30,
                height: 30,
                quality: 100
            }
        },
        cardSm: {
            modifiers: {
                format: 'webp',
                width: 90,
                height: 84,
                quality: 100
            }
        },
        cardMd: {
            modifiers: {
                format: 'webp',
                quality: 100
            }
        },
        marketingCtaCard: {
            modifiers: {
                format: 'webp',
                width: 64,
                height: 64,
                quality: 100,
                lazy: true
            }
        }
    }
};