import InvestmentQuery from "~/services/queries/getInvestmentIdeas.gql";
import AllInvestmentQuery from "~/services/queries/getScreenerSecurities.gql";
import AllUnderlyingInvestmentQuery from "~/services/queries/getUnderlyingSecurities.gql";
import type { DocumentNode } from 'graphql';
import cardsQuery from "~/services/queries/getInvestmentIdeasCardResults.gql";
import { ApolloClient } from 'apollo-client';
import type { Types as ApiTypes } from "@mca-dfd-api/graphql-schema";
import { GetIwtUniverseIds } from "~/configs/common";
import ScreenerSecurity from "~/models/screenerSecurity";
import type { getInvestmentIdeas, IInvestmentIdeasPayload, SecuritiesRequest, ScreenerSecuritiesResponse } from "~/types/investment/ideas";
import ScreenerSecuritiesService from "../screener/securities";


export default class InvestmentIdeasService {
    constructor(public gqlClient: ApolloClient<any>) { }

    async getInvestmentIdeas(payload: IInvestmentIdeasPayload) {
        return await this.gqlClient.query<getInvestmentIdeas>({
            query: InvestmentQuery,
            variables: {
                id: payload.id,
                page: payload.page,
                pageSize: payload.pageSize,
                securityType: payload.securityType,
                assetAllocation: payload.assetAllocation,
                investmentStrategy: payload.investmentStrategy,
                skipDetailView: payload.skipDetailView || false
            }
        });
    }
    async getSecurities(request: SecuritiesRequest): Promise<ScreenerSecuritiesResponse> {
        if (request.isScreener) {
            return await this.getScreenerSecurities(request);
        }
        return await this.getPortfolioSecurities(request);
    }
    private formatScreenerResults(results: ApiTypes.PageScreenerSecurity): ScreenerSecuritiesResponse {
        const securities: ScreenerSecurity[] = [];
        results.securities?.forEach((sec) => {
            securities.push(new ScreenerSecurity(sec as ApiTypes.ScreenerSecurity));
        });
        return {
            page: results.page,
            pageSize: results.pageSize,
            total: results.total || 0,
            securities: securities
        } as ScreenerSecuritiesResponse;
    }
    private getQuery(request: SecuritiesRequest): DocumentNode {

        if (request.isCard) {
            return request.isScreener ? cardsQuery.getScreenerSecurities : cardsQuery.getUnderlyingSecurities;
        }

        if (request.isScreener) {
            return request.securityType == 'ST' ? AllInvestmentQuery.getStockSecurities : AllInvestmentQuery.getSecurities;
        }
        return AllUnderlyingInvestmentQuery;

    }
    async getScreenerSecurities(request: SecuritiesRequest): Promise<ScreenerSecuritiesResponse> {
        const query = this.getQuery(request);
        const universeIds = GetIwtUniverseIds([request.securityType]);
        const screenerService = new ScreenerSecuritiesService(this.gqlClient, query);
        request.payload.universeIds = universeIds;
        return await screenerService.getSecurities(request.payload);
    }
    async getPortfolioSecurities(request: SecuritiesRequest): Promise<ScreenerSecuritiesResponse> {
        const query = this.getQuery(request);
        const res = await this.gqlClient.query({
            query: query,
            variables: {
                performanceId: request.payload.filters[0],
                page: request.payload.page,
                pageSize: request.payload.pageSize,
                sortOrder: request.payload?.sortOrder
            }
        });
        return this.formatScreenerResults(res.data.screener);
    }
}
