import type { ScreenerSecuritiesResponse, SecurityRequestPayload } from "~/types/investment/ideas";
import { addDays, dateFormat } from '@mca-components-ui/filters';

import { ApolloClient } from 'apollo-client';
import GetDividendQuery from "~/services/queries/getUpcomingDividends.gql";
import ScreenerSecuritiesService from '../screener/securities';

export default class UpcomingDividendsService {
    constructor(public gqlClient: ApolloClient<any>) { }

    async getSecurities(request: SecurityRequestPayload): Promise<ScreenerSecuritiesResponse> {
        const screenerService = new ScreenerSecuritiesService(this.gqlClient, GetDividendQuery);
        const startDate = dateFormat(new Date().toString(),'YYYY-MM-DD');
        const addDaysToStartDate: any = addDays(90,'day');
        const endDate = dateFormat(addDaysToStartDate.$d.toString(), 'YYYY-MM-DD');
        const filters = ['PayDate:BTW:' + startDate + ':' + endDate];
        request.filters = filters;
        return await screenerService.getSecurities(request);
    }
}
