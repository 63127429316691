import { ActionTree, MutationTree } from "vuex";
import InvestmentIdeasService from "~/services/investments/ideas";
import { State as RootState } from "~/store";
import type { SecuritiesRequest } from "~/types/investment/ideas";


export const state = () => ({
    updateTime: new Date()
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {

    CARD_UPDATE_TIME: state => state.updateTime = new Date()

};


export const actions: ActionTree<RootState, State> = {
    async fetchSecurities({}, securitiesRequest: SecuritiesRequest) {
        
        const service = new InvestmentIdeasService(this.app.apolloProvider!.defaultClient);
        const securitiesResponse = await service.getSecurities(securitiesRequest);
        return securitiesResponse;
    }
};
