import GetNewsQuery from "~/services/queries/getNews.gql";
import { ApolloClient } from 'apollo-client';
import { DocumentsCollectionResponse, IArticlePayload } from "~/types/article/article";

class ArticleService {
    constructor(public gqlClient: ApolloClient<any>) {}
    async getArticle(payload: IArticlePayload) {
        return this.gqlClient.query<DocumentsCollectionResponse>({
            query: GetNewsQuery,variables:{
                keyword:payload.keyword,
                page: payload.page,
                pageSize: payload.pageSize,
                contentTypeName: payload.contentTypeName,
                access: payload.access,
                status: payload.status
            }
        });
    }
}
export default ArticleService;
