import { ActionTree, MutationTree, GetterTree } from 'vuex';
import type {State as RootState} from '~/store';
import { UserSegment, UserVariation, DEFAULT_USER_SEGMENT, INDIVIDUAL_SEGMENTS, PROFESSIONAL_SEGMENTS } from '@/configs/user';
import { SEGMENT_COOKIE_NAME } from '@/configs/user';

export const state = () => ({
    segment: DEFAULT_USER_SEGMENT,
    isSaved: false,
    abTesting: {
        variation: 'a' as UserVariation
    },
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {
    SET_USER_SEGMENT: (state, segment: UserSegment) => {
        state.segment = segment;
        state.isSaved = true;
    },
    SET_SAVED_USER_SEGMENT: (state, segment: UserSegment) => {
        state.segment = segment;
        state.isSaved = true;
    },
    SET_USER_VARIATION: (state, variation: UserVariation) => {
        state.abTesting.variation = variation;
    }
};

export const actions: ActionTree<RootState, State> = {
    async loadUserSegment({ commit }) {
        const userSegment = this.app.$cookies.get(SEGMENT_COOKIE_NAME);

        if(userSegment) {
            commit('SET_SAVED_USER_SEGMENT', userSegment);

            this.app.$gtm.push({userSegment});
        }
    },
    async saveUserSegment({ commit }, userSegment: UserSegment) {

        const validSegments = Object.keys({...INDIVIDUAL_SEGMENTS, ...PROFESSIONAL_SEGMENTS}) as UserSegment[];

        if(!validSegments.includes(userSegment)) {
            throw new Error('Invalid user segment');
        }

        this.app.$cookies.set(SEGMENT_COOKIE_NAME, userSegment, {
            path: '/',
            sameSite: true,
            secure: process.env.nodeEnv !== 'local',
            maxAge: 60 * 60 * 24 * 30
        });

        commit('SET_USER_SEGMENT', userSegment);

        this.app.$gtm.push({userSegment});
    },
    /**
     * Randomly selects an a/b variation 50% weight
     */
    async setRandomUserVariation({ commit }) {

        const variation: UserVariation = Math.random() < 0.5 ? 'a' : 'b';

        commit('SET_USER_VARIATION', variation);
    }
};

export const getters: GetterTree<State, RootState> = {
    userType: (state) => {
        return state.segment === 'indinv' ? 'ii' : 'fp';
    },
    isSegmented: (state) => {
        return state.segment !== 'unknown';
    }
};