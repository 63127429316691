import { ActionTree, MutationTree,GetterTree } from "vuex";
import { State as RootState } from "~/store";
import SalService, { ITokenResponse } from '~/services/salService';


export const state = () => ({
    issuedToken: {} as ITokenResponse
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {

    UPDATE_SAL_TOKEN: (state, salToken: ITokenResponse) => {
        state.issuedToken = salToken;
    }
};

export const actions: ActionTree<RootState, State> = {
    async getSalToken({ commit, getters }) {
        const salService = new SalService();   
        const authToken = getters.authToken;     
        try {
            if (salService.isTokenExpired(authToken)) {
                const response = await salService.getSalToken(this.$axios);
                commit("UPDATE_SAL_TOKEN", response);                
            }            
        }
        catch (err) {
            console.error('error occured in ge SAl Token...', err);
            throw err;
        }
    }
};
export const getters: GetterTree<State, RootState> = {
    authToken: state => state.issuedToken.auth
};


