import { ActionTree, MutationTree } from 'vuex';
import type { State as RootState } from '~/store';
import GetSecurityByExchangeCodeAndSymbolQuery from "~/services/queries/getSecurityByExchangeCodeAndSymbol.gql";
import GetInvestmentStrategyQuery from "~/services/queries/getInvestmentStrategyQuery.gql";
import GetDocumentsByRelatedSecurities from "~/services/queries/getDocumentsByRelatedSecurities.gql";
import AllInvestmentQuery from "~/services/queries/getScreenerSecurities.gql";
import { GetIwtUniverseIds } from "~/configs/common";
import { Types as ApiTypes } from "@mca-dfd-api/graphql-schema";
import { getDocumentsByRelatedSecuritiesResponse, NewsAnalysisPayload } from '~/types/article/article';
import { SecurityRequestPayload } from '~/types/investment/ideas';

export const state = () => ({
    security: {} as ApiTypes.Security,
    strategy: {} as getInvestmentStrategyQueryResponse
});

export type State = ReturnType<typeof state>;
export type getSecurityByExchangeCodeAndSymbolResponse = {
    getSecurityByExchangeCodeAndSymbol: ApiTypes.Security;
};
export type getInvestmentStrategyQueryResponse = {
    security: ApiTypes.Security;
};
export type getIndexQueryResponse = {
    screener: ApiTypes.PageScreenerSecurity;
};

export const mutations: MutationTree<State> = {
    SET_SECURITY: (state: State, security: ApiTypes.Security) => {
        state.security = security;
    },
    SET_STRATEGY_FOR_SECURITY: (state: State, strategy: getInvestmentStrategyQueryResponse) => {

        if (strategy?.security?.investmentType == ApiTypes.SecurityType.St) strategy.security.name = strategy.security.standardName;

        state.strategy = strategy;
    }
};

export const actions: ActionTree<RootState, State> = {
    async getSecurityByExchangeAndTicker({ commit }, payload) {

        const response = await this.app.apolloProvider!.defaultClient.query<getSecurityByExchangeCodeAndSymbolResponse>({
            query: GetSecurityByExchangeCodeAndSymbolQuery,
            variables: {
                symbol: payload.symbol,
                exchangeCode: payload.exchangeCode,
            } as ApiTypes.QueryGetSecurityByExchangeCodeAndSymbolArgs
        });

        if (!response.data.getSecurityByExchangeCodeAndSymbol) {
            throw new Error(`Security not found for ${payload.exchangeCode}:${payload.symbol}`);
        }

        commit('SET_SECURITY', response.data.getSecurityByExchangeCodeAndSymbol);
    },

    async fetchIndex({ commit }, symbol: string) {
        const universeIds = GetIwtUniverseIds(["XI"]);
        const { data: { screener } } = await this.app.apolloProvider!.defaultClient.query<getIndexQueryResponse>({
            query: AllInvestmentQuery.getSecurities,
            variables: {
                page: 1,
                pageSize: 1,
                filters: [`symbol:IN:${symbol}`],
                universeIds,
            }
        });

        commit('SET_SECURITY', screener.securities![0]);
    },

    async getStrategyForSecurity({ commit }, securityId: string) {
        const response = await this.app.apolloProvider!.defaultClient.query<getInvestmentStrategyQueryResponse>({
            query: GetInvestmentStrategyQuery,
            variables: {
                id: securityId
            }
        });

        commit('SET_STRATEGY_FOR_SECURITY', response.data.security);
        return response.data.security;
    },

    async getDocumentsByRelatedSecurities({ }, payload: NewsAnalysisPayload) {
        const response = await this.app.apolloProvider!.defaultClient.query<getDocumentsByRelatedSecuritiesResponse>({
            query: GetDocumentsByRelatedSecurities,
            variables: {
                secIds: [payload.secId],
                keyword: payload.keyword,
                page: payload.page,
                pageSize: payload.pageSize
            }
        });
        return response.data.getDocumentsByRelatedSecurities;
    },

    async fetchExchangeSecurities({ }, screenerRequest: SecurityRequestPayload) {
        const response = await this.app.apolloProvider!.defaultClient.query<getIndexQueryResponse>({
            query: AllInvestmentQuery.getSecurities,
            variables: {
                page: screenerRequest.page,
                pageSize: screenerRequest.pageSize,
                universeIds: screenerRequest.universeIds,
                filters: screenerRequest.filters,
                sortOrder: screenerRequest.sortOrder
            }
        });
        if (!response.data.screener) throw new Error("Response not found");
        return response.data.screener;
    },
};
