
import { ILinks, ISection, ILink  } from '@mca-components-ui/footer-v2';
export class FooterResult {
    public name:string;
    public link: ILink[];

    constructor(section: ISection) {
        this.name= section.column.name;
        this.link= section.column.links.map((link: ILinks)=>{
            return new Links(link);
        });
    }
}

class Links {
    public is_external_link:boolean;
    public title:string;
    public url:string;

    constructor(link: ILinks) {
        this.is_external_link=link.link?.is_external_link ||false;
        this.title=link?.link?.title || '';
        this.url=link.link?.url || '';
    }
}

export default FooterResult;