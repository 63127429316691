import { Context } from '@nuxt/types';
import { HttpLink } from '@apollo/client/link/http';
import { from } from '@apollo/client/link/core';
import { InMemoryCache } from '@apollo/client/cache';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { onError } from '@apollo/client/link/error';
import { logErrorMessages } from '@vue/apollo-util';

export default (context: Context) => {

    const timeoutLink = new ApolloLinkTimeout(context.$config.apolloLinkTimeout);

    const httpLink = new HttpLink({
        uri: `${context.$config.baseApiUrl}/graphql`,
        credentials: 'omit',
        fetchOptions: {
            mode: 'cors'
        },
        useGETForQueries: true,

        // Compress %20 out of long GET queries
        fetch: (url, options) => {
            const compressedUrl = url.toString().replace(/(%20)+/g, '%20');
            return fetch(compressedUrl, options);
        },
    });

    const errorLink = onError(error => {
        console.log('GraphQL:', error);
        logErrorMessages(error);
    });

    const link = from([errorLink, timeoutLink, httpLink]);
    const cache = new InMemoryCache();

    return {
        link,
        cache,
        defaultHttpLink: false
    };
};