import { ActionTree, MutationTree, GetterTree } from "vuex";
import InvestmentIdeasService from "~/services/investments/ideas";
import { State as RootState } from "~/store";
import type { IInvestmentIdeasPayload, IInvestmentIdea, IInvestmentIdeaResponse } from "~/types/investment/ideas";
import { SECURITY_TYPE_FILTER } from "@/configs/investment-ideas";


export const state = () => ({
    investmentIdeas: {} as IInvestmentIdeaResponse,
    filters: {} as IInvestmentIdeasPayload,
    investmentIdea: {} as IInvestmentIdea,
    docPage: 1
});

export type State = ReturnType<typeof state>;

export const mutations: MutationTree<State> = {
    SET_INVESTMENT_IDEAS: (state: State, investmentData: IInvestmentIdeaResponse) => {
        state.investmentIdeas = { ...investmentData };
    },
    SET_FILTERS: (state: State, filters: IInvestmentIdeasPayload) => {
        state.filters = filters;
    },
    SET_INVESTMENT_IDEA: (state: State, idea: IInvestmentIdea) => {
        state.investmentIdea = { ...idea };
    },
    SET_DOC_PAGE: (state: State, docPage: number) => {
        state.docPage = docPage;
    },
};

export const actions: ActionTree<RootState, State> = {
    async fetchLatest({ commit }, payload: IInvestmentIdeasPayload) {
        const service = new InvestmentIdeasService(this.app.apolloProvider!.defaultClient);
        const investmentResponse = await service.getInvestmentIdeas(payload);
        commit("SET_INVESTMENT_IDEAS", investmentResponse.data.getInvestmentIdeas);
        return investmentResponse.data.getInvestmentIdeas;
    },
    async fetchSalIdeas({ }, payload: IInvestmentIdeasPayload) {
        const service = new InvestmentIdeasService(this.app.apolloProvider!.defaultClient);
        const investmentResponse = await service.getInvestmentIdeas(payload);
        const investmentIdeas = investmentResponse.data.getInvestmentIdeas;

        const ideasList = {} as Record<string, Record<string, string | Record<string, string>[]>>;
        investmentIdeas.ideas?.forEach(idea => {
            const secName = SECURITY_TYPE_FILTER.find(x => x.value === idea.securityType)!.text || "";
            if (!ideasList[idea.securityType])
                ideasList[idea.securityType] = { name: secName, ideas: [] };
            (ideasList[idea.securityType].ideas as Record<string, string>[]).push({ id: idea.id, name: idea.name });
        });

        return ideasList;
    },
    async fetchIdea({ commit }, id: string) {
        const service = new InvestmentIdeasService(this.app.apolloProvider!.defaultClient);
        const investmentResponse = await service.getInvestmentIdeas({
            id
        });
        const ideas = investmentResponse.data.getInvestmentIdeas.ideas || [{}];
        if (ideas[0]) {
            commit("SET_INVESTMENT_IDEA", ideas[0]);
        }
        return ideas[0];
    },
    async currentPage({ commit }, payload: number) {
        commit('SET_DOC_PAGE', payload);
    }
};

export const getters: GetterTree<State, RootState> = {
    ideas: state => state.investmentIdeas.ideas || [],
    idea: state => state.investmentIdea || {},
    total: state => state.investmentIdeas.total || 0,
    filters: state => state.filters || {}
};