export type AdvertPlacement = "PUSHDOWN_SPLASH" | "TOP_BANNER" | "MIDDLE_BANNER" | "BOTTOM_BANNER" | "TOP_RIGHT" | "MIDDLE_RIGHT" | "BOTTOM_RIGHT" | "IN_CONTENT_ONE" | "IN_CONTENT_TWO" | "DYNAMIC_MIDDLE_BANNER" | "DYNAMIC_BOTTOM_RIGHT_BANNER";
export type AdvertContainerSize = "full-width" | "billboard" | "pushdown-splash" | "medium-rectangle" | "super-leaderboard-desktop" | "leaderboard-desktop" | "leaderboard-mobile" | "half-page";
export type Size = {
    width: number;
    height: number;
};

export type AdvertSize = {
    position: string;
    description?: string;
    container: AdvertContainerSize;
    sizes: {
        desktop: Size[];
        mobile: Size[];
    }
};

export const PUSHDOWN_SESSION_TIME = 2; // Time in hours between sessions
export const SITE = 'ms.au';
export const FIRM_TYPE = new Map<string, string>([
    ['asset-management-firm', 'Asset Management Firm'],
    ['financial-advice-practice', 'Financial Advice Practice'],
    ['dealer-group', 'Dealer Group'],
    [' wealth-management-firm', 'Wealth Management Firm'],
    ['private-banktrust-company', 'Private Bank/Trust Company'],
    ['other', 'Other']
]);

export const ROUTE_ADVERT_PLACEMENTS = new Map<string, (isDesktop: boolean) => AdvertPlacement[]>([
    ['index', () => ['TOP_RIGHT', 'MIDDLE_RIGHT', 'DYNAMIC_MIDDLE_BANNER', 'BOTTOM_RIGHT']],

    ['insights-category', () => ['TOP_RIGHT', 'MIDDLE_BANNER', 'IN_CONTENT_ONE']],
    ['insights-category-id-slug', () => ['TOP_RIGHT', 'MIDDLE_RIGHT', 'IN_CONTENT_ONE', 'IN_CONTENT_TWO']],
    ['insights-author-id-slug', () => ['TOP_RIGHT', 'BOTTOM_RIGHT']],
    ['insights-topic-tag', () => ['TOP_RIGHT', 'BOTTOM_RIGHT']],
    ['mca-article', () => ['TOP_RIGHT', 'BOTTOM_RIGHT', 'IN_CONTENT_ONE', 'IN_CONTENT_TWO']],

    ['investments-security-exchangeCode-symbol-view', () => ['TOP_RIGHT', 'DYNAMIC_MIDDLE_BANNER']],
    ['investments-ideas', () => ['MIDDLE_BANNER', 'BOTTOM_RIGHT']],
    ['mca-security', () => ['TOP_RIGHT']],

    ['markets-region', () => ['TOP_RIGHT', 'MIDDLE_BANNER']],
    ['markets-asx-market-report', () => ['IN_CONTENT_ONE', 'TOP_RIGHT', 'MIDDLE_RIGHT', 'BOTTOM_RIGHT']],
    ['markets-global-market-report-market', () => ['IN_CONTENT_ONE', 'TOP_RIGHT', 'MIDDLE_RIGHT', 'BOTTOM_RIGHT']],
    ['markets-upcoming-dividends', () => ['DYNAMIC_MIDDLE_BANNER', 'TOP_RIGHT', 'MIDDLE_RIGHT']],

    ['learn-slug', () => ['TOP_RIGHT', 'IN_CONTENT_ONE']],

    ['mca-learn-article', () => ['TOP_RIGHT', 'MIDDLE_RIGHT', 'IN_CONTENT_ONE', 'IN_CONTENT_TWO']],

    ['about-advertise', () => ['TOP_RIGHT']],
    ['about-contact', () => ['TOP_RIGHT']],

    ['search-type-term', () => ['TOP_RIGHT', 'BOTTOM_RIGHT']],

    ['sitemap', () => ['TOP_RIGHT']],
    ['sitemap-year', () => ['TOP_RIGHT']],
    ['sitemap-year-month', () => ['TOP_RIGHT']],
    ['mca-search',()=> ['TOP_RIGHT','DYNAMIC_BOTTOM_RIGHT_BANNER']],
    ['search-type', () => ['TOP_RIGHT', 'DYNAMIC_BOTTOM_RIGHT_BANNER']],
]);

export const COMMON_ADVERT_PLACEMENTS: (isSplashViewable: boolean) => AdvertPlacement[] = (isSplashViewable: boolean) => [(isSplashViewable ? 'PUSHDOWN_SPLASH' : 'TOP_BANNER'), 'BOTTOM_BANNER'];

export const AD_SIZES: Record<AdvertPlacement, AdvertSize> = {
    PUSHDOWN_SPLASH: {
        position: "pushdown",
        container: "pushdown-splash",
        description: "Above masthead",
        sizes: {
            desktop: [
                { width: 760, height: 500 },
                { width: 970, height: 250 }
            ],
            mobile: [
                { width: 300, height: 250 }
            ]
        }
    },
    TOP_BANNER: {
        position: "top",
        container: "billboard",
        description: "Below nav",
        sizes: {
            desktop: [
                { width: 970, height: 250 },
                { width: 728, height: 90 }
            ],
            mobile: [
                { width: 300, height: 250 }
            ]
        }
    },
    DYNAMIC_MIDDLE_BANNER: {
        position: "middle",
        container: "full-width",
        description: "dynamic middle banner in homepage",
        sizes: {
            desktop: [],
            mobile: []
        }
    },
    MIDDLE_BANNER: {
        position: "middle",
        container: "billboard",
        description: "Middle of content",
        sizes: {
            desktop: [
                { width: 970, height: 250 },
                { width: 728, height: 90 }
            ],
            mobile: [
                { width: 300, height: 250 }
            ]
        }
    },
    BOTTOM_BANNER: {
        position: "bottom",
        container: "billboard",
        description: "Above footer",
        sizes: {
            desktop: [
                { width: 970, height: 250 },
                { width: 728, height: 90 }
            ],
            mobile: [
                { width: 300, height: 250 }
            ]
        }
    },
    TOP_RIGHT: {
        position: "topright",
        container: "half-page",
        description: "Top of right column",
        sizes: {
            desktop: [
                { width: 300, height: 600 },
                { width: 300, height: 250 }
            ],
            mobile: [
                { width: 300, height: 250 }
            ]
        }
    },
    MIDDLE_RIGHT: {
        position: "middleright",
        container: "half-page",
        description: "Middle of right column",
        sizes: {
            desktop: [
                { width: 300, height: 600 },
                { width: 300, height: 250 }
            ],
            mobile: [
                { width: 300, height: 250 }
            ]
        }
    },
    BOTTOM_RIGHT: {
        position: "bottomright",
        container: "full-width",
        description: "Bottom of right column",
        sizes: {
            desktop: [],
            mobile: []
        }
    },
    IN_CONTENT_ONE: {
        position: "offer1",
        container: "full-width",
        description: "First ad in article body",
        sizes: {
            desktop: [],
            mobile: []
        }
    },
    IN_CONTENT_TWO: {
        position: "offer2",
        container: "full-width",
        description: "Second ad in article body",
        sizes: {
            desktop: [],
            mobile: []
        }
    },
    DYNAMIC_BOTTOM_RIGHT_BANNER :{
        position: "bottomright",
        container: "full-width",
        description: "Bottom of right column for dynamic content",
        sizes: {
            desktop: [],
            mobile: []
        }
    }
};