
import Vue from 'vue';
import { MdsButton } from '@mds/button';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsSection from '@mds/section';
import * as navigation from '@/configs/nav';
import NavList from './_nav-list.vue';
import type { Route } from 'vue-router';

export default Vue.extend({
    props: {
        currentRoute: {
            type: Object as () => Route,
            required: true
        }
    },
    data() {
        return {
            navigation,
            expanded: {
                markets: false,
                investments: false,
                insights: false,
                learn: false,
                solutions: false
            }
        };
    },
    components: {
        MdsButton,
        MdsListGroup,
        MdsListGroupItem,
        MdsSection,
        NavList
    },
    watch: {
        '$route'() {
            this.close();
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
});
