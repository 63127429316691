import AuthorQuery from "~/services/queries/getAuthor.gql";
import { ApolloClient } from 'apollo-client';
import {AuthorResponse, IAuthor} from "~/types/author/author";

class AuthorService {
    constructor(public gqlClient: ApolloClient<any>) {}
    async getAuthor(payload: IAuthor) {
        return this.gqlClient.query<AuthorResponse>({
            query: AuthorQuery,variables:{
                id:payload.id,
                page: payload.docPage,
                pageSize: payload.docPageSize
            }
        });
    }
}
export default AuthorService;
